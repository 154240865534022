import { create } from "zustand";

const useModalStore = create((set) => ({
  isModalOpen: false,
  modalPosition: { top: 0, left: 0 },
  modalContent: null,
  clickedIcon: null,
  openModal: (position, content, iconId) =>
    set({
      isModalOpen: true,
      modalPosition: position,
      modalContent: content,
      clickedIcon: iconId,
    }),
  closeModal: () =>
    set({
      isModalOpen: false,
      modalPosition: { top: 0, left: 0 },
      modalContent: null,
      clickedIcon: null,
    }),
}));

export default useModalStore;
