import React from "react";
import style from "./PavementThicknessSection.module.css";
import packLoadImg from "../../assets/img/packLoadImg.png";

/*
평면설정 및 예비단면설정 - 포장층두께 이미지데이터
재료물성 입력 - 콘크리트 포장 단면 개략도
*/
const PavementThicknessSection = ({ dimensions }) => {
  return (
    <>
      <div className={style.img}>
        <img src={packLoadImg} alt="Road" className={style.roadImage} />
        <div className={style.thicknessWrap}>
          <span className={style.thickness}>
            {parseFloat(dimensions.slabThickness) || 0}
          </span>
          <span className={style.thickness}>
            {parseFloat(dimensions.leanConcreteThickness) || 0}
          </span>
          <span className={style.thickness}>
            {parseFloat(dimensions.subbaseLayerThickness) || 0}
          </span>
        </div>
      </div>
    </>
  );
};

export default PavementThicknessSection;
