const tabData = {
  "차종별 교통량 및 비율": {
    mainTable: [
      {
        headers: [],
        data: [],
      },
    ],
  },
  "월별 교통량 비율": {
    mainTable: [
      {
        headers: [
          { Header: "구분", colSpan: 1, rowSpan: 1 },
          { Header: "1종", colSpan: 1, rowSpan: 1 },
          { Header: "2종", colSpan: 1, rowSpan: 1 },
          { Header: "3종", colSpan: 1, rowSpan: 1 },
          { Header: "4종", colSpan: 1, rowSpan: 1 },
          { Header: "5종", colSpan: 1, rowSpan: 1 },
          { Header: "6종", colSpan: 1, rowSpan: 1 },
          { Header: "7종", colSpan: 1, rowSpan: 1 },
          { Header: "8종", colSpan: 1, rowSpan: 1 },
          { Header: "9종", colSpan: 1, rowSpan: 1 },
          { Header: "10종", colSpan: 1, rowSpan: 1 },
          { Header: "11종", colSpan: 1, rowSpan: 1 },
          { Header: "12종", colSpan: 1, rowSpan: 1 },
        ],
        data: [
          {
            rowSpan: 2,
            category: "비율",
            subCategory: "0.92",
            values: [
              "0.89",
              "1.00",
              "1.04",
              "1.03",
              "1.00",
              "0.93",
              "1.02",
              "1.33",
              "1.50",
              "0.23",
              "0.12",
              "0.12",
            ],
          },
        ],
      },
    ],
  },
  "시간별 교통량 비율": {
    mainTable: [
      {
        headers: [
          { Header: "구분", colSpan: 1, rowSpan: 1 },
          { Header: "1종", colSpan: 1, rowSpan: 1 },
          { Header: "2종", colSpan: 1, rowSpan: 1 },
          { Header: "3종", colSpan: 1, rowSpan: 1 },
          { Header: "4종", colSpan: 1, rowSpan: 1 },
          { Header: "5종", colSpan: 1, rowSpan: 1 },
          { Header: "6종", colSpan: 1, rowSpan: 1 },
          { Header: "7종", colSpan: 1, rowSpan: 1 },
          { Header: "8종", colSpan: 1, rowSpan: 1 },
          { Header: "9종", colSpan: 1, rowSpan: 1 },
          { Header: "10종", colSpan: 1, rowSpan: 1 },
          { Header: "11종", colSpan: 1, rowSpan: 1 },
          { Header: "12종", colSpan: 1, rowSpan: 1 },
        ],
        data: [
          {
            category: "1시",
            values: [
              "270.44",
              "16.65",
              "52.42",
              "43.89",
              "13.00",
              "8.11",
              "7.21",
              "2.79",
              "0.47",
              "1.50",
              "0.23",
              "0.12",
            ],
          },
          {
            category: "2시",
            values: [
              "177.77",
              "10.94",
              "34.36",
              "28.85",
              "8.54",
              "5.33",
              "7.74",
              "1.83",
              "0.31",
              "0.20",
              "0.08",
              "0.05",
            ],
          },
          {
            category: "3시",
            values: [
              "130.49",
              "8.03",
              "25.30",
              "21.18",
              "6.27",
              "3.91",
              "3.48",
              "1.35",
              "0.23",
              "0.18",
              "0.15",
              "0.06",
            ],
          },
          {
            category: "4시",
            values: [
              "109.69",
              "6.75",
              "21.26",
              "17.80",
              "5.27",
              "3.29",
              "2.92",
              "1.13",
              "0.19",
              "1.24",
              "0.80",
              "0.55",
            ],
          },
        ],
      },
    ],
  },

  "교통하중 분석결과": {
    mainTable: [],
  },
};

export default tabData;
