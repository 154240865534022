import React from "react";
import style from "./selectPage.module.css";
import logo from "../../assets/img/header_logo.png";

import { PiFileText, PiFolderOpen } from "react-icons/pi";
import { useNavigate } from "react-router-dom";

const SelectPage = () => {
  const navigator = useNavigate();

  const handleTaskOpen = () => {
    navigator("/task-info");
  };

  return (
    <div className={style.selectPageWrap}>
      <div className={style.selectContainer}>
        <div className={style.selectPageLogo}>
          <img src={logo} alt="logo"></img>
          <h2>한국형 고속도로 포장 설계</h2>
        </div>
        <div className={style.selectContent}>
          <div className={style.newTask} onClick={handleTaskOpen}>
            <p className={style.icon}>
              <PiFileText style={{ color: "white", fontSize: "24px" }} />
            </p>
            <p>새과업</p>
          </div>
          <div className={style.fileOpen}>
            <p className={style.icon}>
              <PiFolderOpen style={{ fontSize: "24px" }} />
            </p>
            <p>파일열기</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectPage;
