import React, { forwardRef, useEffect, useRef, useState } from "react";
import style from "./Input.module.css";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale";

import DatePicker from "react-datepicker";
import { icons } from "../../contents/icons";

/* 
기본 input 값
label : 라벨링이름,
name : 변수명,
type : text | number
icon : title 옆 아이콘
*/
const InputBasic = ({
  label,
  type,
  step,
  customStyle,
  value,
  onChange,
  name,
  placeholder,
  icon,
  disabled,
  readOnly,
}) => {
  const handleChange = (e) => {
    if (type === "text") {
      const regex = /^[a-zA-Z가-힣\s]*$/;
      if (regex.test(e.target.value) || e.target.value === "") {
        onChange(e);
      }
    } else {
      onChange(e);
    }
  };

  return (
    <div className={style.inputWrap}>
      <div className={style.inputLabelWrap}>
        <label className={label ? style.inputLabel : style.hiddenLabel}>
          {label}
        </label>
        <p>{icon}</p>
      </div>
      <input
        name={name}
        className={style.inputBasic}
        type={type}
        step={step}
        placeholder={placeholder}
        style={customStyle}
        disabled={disabled}
        value={value}
        readOnly={readOnly}
        onChange={handleChange}
      />
    </div>
  );
};

/* 
DropInput 
label : 라벨링이름,
name : 변수명,
options: 드롭다운 리스트설정,
icon : title 옆 아이콘
*/
const InputDrop = ({ label, options, customStyle, name, href, icon }) => {
  const [isDropdownUp, setIsDropdownUp] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    options[0] || { label: ",", value: "" }
  );

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={style.inputWrap}>
      <div className={style.inputLabelWrap}>
        <label className={style.inputLabel}>{label}</label>
        <p>{icon}</p>
      </div>
      <div
        className={style.inputDrop}
        style={customStyle}
        name={name}
        ref={dropdownRef}
        onClick={toggleDropdown}
      >
        <span>{selectedOption.label}</span>
        {isOpen ? (
          <icons.PiCaretUp className={style.dropdownIcon} size={14} />
        ) : (
          <icons.PiCaretDown className={style.dropdownIcon} size={14} />
        )}
        {isOpen && (
          <ul
            className={`${style.dropdownMenu} ${
              isDropdownUp ? style.dropdownUp : style.dropdownDown
            }`}
            style={{ maxHeight: isDropdownUp ? "auto" : "200px" }}
          >
            {options.map((option) => (
              <li
                key={option.value}
                className={style.dropdownItem}
                onClick={() => handleOptionClick(option)}
              >
                <a href={href} className={style.dropdownLink}>
                  {option.label}
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

/* 
InputDropUp 
label : 라벨링이름,
name : 변수명,
options: 드롭업 리스트설정,
icon : title 옆 아이콘
*/

const InputDropUp = ({
  label,
  options,
  customStyle,
  name,
  href,
  icon,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    options[0] || { label: ",", value: "" }
  );

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    if (onChange) {
      onChange(option);
    }
  };

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={style.inputWrap}>
      <div className={style.inputLabelWrap}>
        <label className={style.inputLabel}>{label}</label>
        <p>{icon}</p>
      </div>
      <div
        className={style.inputDrop}
        style={customStyle}
        name={name}
        ref={dropdownRef}
        onClick={toggleDropdown}
      >
        <span>{selectedOption.label}</span>
        {isOpen ? (
          <icons.PiCaretUp className={style.dropdownIcon} size={14} />
        ) : (
          <icons.PiCaretDown className={style.dropdownIcon} size={14} />
        )}
        {isOpen && (
          <ul className={`${style.dropdownMenu} ${style.dropdownUp}`}>
            {options.map((option, index) => (
              <li
                key={`${option.value}-${index}`}
                className={style.dropdownItem}
                onClick={() => handleOptionClick(option)}
              >
                <a href={href} className={style.dropdownLink}>
                  {option.label}
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

/* 
input 안에 텍스트 적힌 값 
label : 라벨링이름,
name : 변수명,
type : text | number,
icon : title 옆 아이콘
*/
const InputText = ({ label, name, type, value, icon, disabled }) => {
  return (
    <div className={style.inputWrap}>
      <div className={style.inputLabelWrap}>
        <label className={style.inputLabel}>{label}</label>
        <p>{icon}</p>
      </div>
      <div className={style.inputCustomText}>
        <input
          className={style.inputText}
          name={name}
          disabled={disabled}
          type={type}
          value={value}
        />
        <div className={style.inputSuffix}>년</div>
      </div>
    </div>
  );
};

/*
title 옆 min & max 값 span
*/
const InputAddText = ({
  label,
  text,
  value,
  onChange,
  name,
  type,
  step,
  icon,
  max,
  min,
}) => {
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    validate(value);
  }, [value, min, max]);

  const validate = (value) => {
    const numValue = parseFloat(value);

    if (value === "") {
      setIsValid(true);
    } else if (
      (min !== undefined && numValue < min) ||
      (max !== undefined && numValue > max)
    ) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  };
  const handleChange = (e) => {
    const newValue = e.target.value;
    validate(newValue);
    onChange(e);
  };

  return (
    <div className={style.inputWrap}>
      <div className={style.inputTitleWrap}>
        <div className={style.inputLabelWrap}>
          <label className={style.inputLabel}>{label}</label>
          {/* <div className={style.inputAddText}>{text}</div> */}
          {!isValid && (
            <div className={style.inputAddText}>
              {min !== undefined && max !== undefined
                ? `※ ${min} 이상 ${max} 이하`
                : min !== undefined
                ? `※ ${min} 이상`
                : `※ ${max} 이하`}
            </div>
          )}
          <p>{icon}</p>
        </div>
      </div>
      <div className={style.inputCustomText}>
        <input
          className={`${style.inputText} ${!isValid ? style.invalid : ""}`}
          name={name}
          type={type}
          step={step}
          max={max}
          min={min}
          value={value}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

const CustomInput = forwardRef(
  ({ value, onClick, onChange, customStyle, name }, ref) => (
    <div className={style.inputDrop} ref={ref}>
      <input
        value={value}
        className={style.inputField}
        style={customStyle}
        name={name}
        onClick={onClick}
        onChange={onChange}
        placeholder="YYYY.MM.DD"
      />
      <icons.PiCalendarCheckLight className={style.icon} onClick={onClick} />
    </div>
  )
);

/* 
input 안에 날짜(달력) 적힌 값 
label : 라벨링이름,
name : 변수명,
type : text | number,
*/
const InputDate = ({
  label,
  customStyle,
  name,
  type,
  selectedDate,
  onChange,
  minDate,
  maxDate,
}) => {
  const handleInputChange = (date) => {
    onChange(date);
  };

  const getDayClassName = (date) => {
    const day = date.getDay();
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    let className = "";

    switch (day) {
      case 0:
        className = style.sunday;
        break;
      case 6:
        className = style.saturday;
        break;
      default:
        className = style.weekday;
    }

    if (date.getTime() === today.getTime()) {
      className += ` ${style.today}`;
    }

    if (selectedDate && date.getTime() === selectedDate.getTime()) {
      className += ` ${style.selectedDay}`;
    }

    if (date.getMonth() !== selectedDate?.getMonth()) {
      className += ` ${style.nonCurrentMonthDay}`;
    }
    if (name !== "Traffic_Opening_Date" && date > today) {
      className += ` ${style.futureDate}`;
    }
    return className;
  };

  return (
    <div className={style.inputWrap}>
      <label className={style.inputLabel}>{label}</label>
      <DatePicker
        className={style.rdrMonthAndYearWrapper}
        name={name}
        type={type}
        dateFormat="yyyy.MM.dd"
        shouldCloseOnSelect={false}
        locale={ko}
        selected={selectedDate}
        onChange={handleInputChange}
        minDate={minDate}
        maxDate={maxDate}
        customInput={
          <CustomInput
            customStyle={customStyle}
            value={selectedDate ? selectedDate.toISOString().split("T")[0] : ""}
          />
        }
        dayClassName={getDayClassName}
        wrapperClassName={style.datePickerWrapper}
      />
    </div>
  );
};

/* 
input 위,아래값 클릭 버튼,
label : 라벨링이름,
name : 변수명,
type : text | number,
min : min 숫자,
max : max 숫자,
*/
const InputNumber = ({ label, min = 0, max = 100, name, type, disabled }) => {
  const [value, setValue] = useState(0);

  const increment = () => {
    if (value < max) {
      setValue((prevValue) => prevValue + 1);
    }
  };

  const decrement = () => {
    if (value > min) {
      setValue((prevValue) => prevValue - 1);
    }
  };

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    if (!isNaN(newValue) && newValue >= min && newValue <= max) {
      setValue(newValue);
    }
  };

  return (
    <div className={style.inputWrap}>
      <label className={style.inputLabel}>{label}</label>
      <div className={style.customInputNumber}>
        <input
          type={type}
          name={name}
          className={style.inputNumber}
          min={min}
          max={max}
          disabled={disabled}
          value={value}
          onChange={handleChange}
        />
        <div className={style.buttonContainer}>
          <button onClick={increment} className={style.incrementButton}>
            <icons.PiCaretUp size={14} />
          </button>
          <button onClick={decrement} className={style.decrementButton}>
            <icons.PiCaretDown size={14} />
          </button>
        </div>
      </div>
    </div>
  );
};

export {
  InputBasic,
  InputDrop,
  InputDropUp,
  InputText,
  InputDate,
  InputAddText,
  InputNumber,
};
