import React, { useState } from "react";
import style from "./Table.module.css";

const HourlyTrafficTable = () => {
  const months = [
    "1월",
    "2월",
    "3월",
    "4월",
    "5월",
    "6월",
    "7월",
    "8월",
    "9월",
    "10월",
    "11월",
    "12월",
  ];

  const hours = [
    "1시",
    "2시",
    "3시",
    "4시",
    "5시",
    "6시",
    "7시",
    "8시",
    "9시",
    "10시",
    "11시",
    "12시",
    "13시",
    "14시",
    "15시",
    "16시",
    "17시",
    "18시",
    "19시",
    "20시",
    "21시",
    "22시",
    "23시",
    "24시",
  ];

  const trafficData = {
    "1월": [
      124, 81, 60, 50, 56, 102, 252, 504, 560, 462, 479, 474, 454, 491, 517,
      525, 535, 586, 611, 560, 462, 479, 474, 454,
    ],
    "2월": [
      110, 75, 55, 45, 52, 98, 230, 480, 530, 440, 460, 455, 435, 470, 495, 510,
      520, 570, 595, 560, 462, 479, 474, 454,
    ],
    "3월": [
      120, 80, 58, 48, 54, 100, 240, 490, 550, 450, 470, 465, 445, 480, 505,
      515, 525, 580, 600, 560, 462, 479, 474, 454,
    ],
  };

  const [selectedMonth, setSelectedMonth] = useState("1월");

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  return (
    <div>
      <label htmlFor="monthSelect">월별 선택 : </label>
      <select
        id="monthSelect"
        className={style.monthSelect}
        value={selectedMonth}
        onChange={handleMonthChange}
      >
        {months.map((month) => (
          <option key={month} value={month}>
            {month}
          </option>
        ))}
      </select>

      <table className={style.table} style={{ fontSize: "14px" }}>
        <thead>
          <tr>
            <th>구분</th>
            <th>1종</th>
            {[...Array(11)].map((_, index) => (
              <th key={index + 2}>{index + 2}종</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {hours.map((hour, hourIndex) => (
            <tr key={hour}>
              <th>{hour}</th>
              <td>{trafficData[selectedMonth][hourIndex]}</td>
              {[...Array(11)].map((_, index) => (
                <td key={index + 2}>{/* Insert calculated data here */}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default HourlyTrafficTable;
