import React, { useState } from "react";
import style from "./Table.module.css";

const VehicleTypeTable = () => {
  const [vehicleNum1, setVehicleNum1] = useState(
    Array.from({ length: 12 }, () => 0)
  );
  const [vehicleNum2, setVehicleNum2] = useState(
    Array.from({ length: 12 }, () => 0)
  );

  const handleInputChange1 = (index, value) => {
    const newValues = [...vehicleNum1];
    newValues[index] = value !== "" ? parseInt(value) : "";
    setVehicleNum1(newValues);
  };
  const handleInputChange2 = (index, value) => {
    const newValues = [...vehicleNum2];
    newValues[index] = value !== "" ? parseInt(value) : "";
    setVehicleNum2(newValues);
  };
  return (
    <>
      <table className={style.table} style={{ fontSize: "14px" }}>
        <thead>
          <tr>
            <th colSpan={14}>차종별 교통량</th>
          </tr>
          <tr>
            <th>구분</th>
            {[...Array(12).keys()].map((i) => (
              <th key={i}>{i + 1}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>AATD</th>
            {vehicleNum1.map((value, index) => (
              <td key={index}>
                <input
                  type="number"
                  value={value}
                  onChange={(e) => handleInputChange1(index, e.target.value)}
                ></input>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
      <table className={style.table} style={{ fontSize: "14px" }}>
        <thead>
          <tr>
            <th colSpan={14}>차종 교통량 환산 ( 방향계수 / 차로계수 ) </th>
          </tr>
          <tr>
            <th>구분</th>
            {[...Array(12).keys()].map((i) => (
              <th key={i}>{i + 1}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>AATD</th>
            {vehicleNum2.map((value, index) => (
              <td key={index}>
                <input
                  type="number"
                  value={value}
                  onChange={(e) => handleInputChange2(index, e.target.value)}
                ></input>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </>
  );
};
export default VehicleTypeTable;
