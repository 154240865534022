import React from "react";
import style from "./Modal.module.css";
import useModalStore from "../../store/useModalStore";

/* 
각 타이틀 옆 아이콘 클릭시 표현되는 모달창
*/
const Modal = () => {
  const { isModalOpen, closeModal, modalPosition, modalContent } =
    useModalStore();

  if (!isModalOpen) return null;

  return (
    <div className={style.modalOverlay} onClick={closeModal}>
      <div
        className={style.modalContent}
        style={{ top: modalPosition.top, left: modalPosition.left }}
        onClick={(e) => e.stopPropagation()}
      >
        {modalContent}
      </div>
    </div>
  );
};

// 모달창 전체적용
// const Modal = () => {
//   const { isModalOpen, closeModal, modalPosition, modalContent } = useModalStore();

//   if (!isModalOpen) return null;

//   return (
//     <div className={style.modalOverlay} onClick={closeModal}>
//       <div
//         className={style.modalContent}
//         style={{
//           top: modalPosition.top,
//           left: modalPosition.left,
//           maxHeight: '80vh',
//           overflowY: 'auto'
//         }}
//         onClick={(e) => e.stopPropagation()}
//       >
//         {modalContent}
//       </div>
//     </div>
//   );
// };

export default Modal;
