import React from "react";
import style from "./Title.module.css";

/* 
각 섹션에 들어가는 title 
*/
const Title = ({ title, style: customStyle, icon }) => {
  return (
    <div className={style.titleWrap} style={customStyle}>
      <p className="title">{title}</p>
      <p className={style.icon}>{icon}</p>
    </div>
  );
};

export default Title;
