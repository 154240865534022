import React, { useEffect, useState } from "react";
import style from "./nav.module.css";
import { Link, useLocation } from "react-router-dom";
import { menuText } from "../../data/nav";
import useTaskStore from "../../store/useTaskStore";

import { icons } from "../../contents/icons";

const Nav = () => {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(null);
  const { routeName, projectSection, designInstitute, titleColor } =
    useTaskStore();

  useEffect(() => {
    const currentPath = location.pathname;
    menuText.forEach((menu) => {
      menu.items.forEach((item) => {
        if (item.path === currentPath) {
          setActiveItem(item.id);
        }
      });
    });
  }, [location.pathname]);

  const handleItemClick = (id) => {
    setActiveItem(id);
  };

  const isDisabled = (item) => {
    return item.title === "종방향 철근";
  };

  return (
    <nav id={style.nav}>
      <div className={style.navWrap}>
        <div className={style.navTitleWrap}>
          <h1 style={{ color: titleColor }}>
            {designInstitute} - {projectSection}
          </h1>
          <p style={{ color: titleColor }}>{routeName} 포장설계 (대안1)</p>
        </div>
      </div>
      <div className={style.navDropDown}>
        <div className={style.navInfo}>
          <div className={style.iconWrap}>
            <icons.BsCheckCircle className={style.blue} />
            <div className={style.blue}>설계가능</div>
          </div>
          <div className={style.iconWrap}>
            <icons.BsExclamationTriangle className={style.red} />
            <div className={style.red}>검토필요</div>
          </div>
          <div className={style.iconWrap}>
            <icons.BsDashCircle className={style.gray} />
            <div className={style.gray}>설계불필요</div>
          </div>
        </div>

        {menuText.map((menu) => (
          <React.Fragment key={menu.id}>
            <div className={style.ulWrap}>
              <ul>
                {menu.items.map((item) => (
                  <li
                    key={item.id}
                    className={`${activeItem === item.id ? style.active : ""} ${
                      isDisabled(item) ? style.isDisabled : ""
                    }`}
                  >
                    <Link
                      to={isDisabled(item) ? "#" : item.path}
                      className={`${style.submenuLi} ${
                        activeItem === item.id ? style.activeLink : ""
                      } ${isDisabled(item) ? style.disabledLink : ""}`}
                      onClick={() =>
                        !isDisabled(item) && handleItemClick(item.id)
                      }
                    >
                      <p className={activeItem === item.id ? style.active : ""}>
                        {item.title}
                      </p>
                      {item.icon}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </React.Fragment>
        ))}
      </div>
    </nav>
  );
};

export default Nav;
