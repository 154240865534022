import { BsCheckCircle } from "react-icons/bs";
import { PiCaretDown } from "react-icons/pi";
import { PiMinusCircle } from "react-icons/pi";
export const menuText = [
  {
    id: 1,
    // title: "과업정보",
    icon: <PiCaretDown size={14} />,
    items: [
      {
        id: 101,
        title: "과업 정보 입력",
        // icon: <BsCheckCircle />,
        path: "/task-info",
      },
    ],
  },
  {
    id: 2,
    // title: "단면설정",
    icon: <PiCaretDown size={14} />,
    items: [
      {
        id: 201,
        title: "평면설정 및 예비 단면설정",
        // icon: <BsCheckCircle />,
        path: "/section-setting",
      },
      {
        id: 202,
        title: "재료 물성 입력",
        // icon: <BsCheckCircle />,
        path: "/material",
      },
    ],
  },
  {
    id: 3,
    // title: "재료설정",
    icon: <PiCaretDown size={14} />,
    items: [
      {
        id: 301,
        title: "불연속면 설계",
        path: "/discontinuous-surface",
        // icon: <BsCheckCircle />,
      },
      {
        id: 302,
        title: "종방향 철근",
        path: "/longitudinal-DirectionRebar",
        icon: <PiMinusCircle size={16} />,
      },
    ],
  },
  {
    id: 4,
    // title: "기상정보 입력",
    icon: <PiCaretDown size={14} />,
    items: [
      {
        id: 401,
        title: "기상 관측소 선택",
        path: "/weather-info",
        // icon: <BsCheckCircle />,
      },
    ],
  },
  {
    id: 5,
    // title: "교통정보 입력",
    icon: <PiCaretDown size={14} />,

    items: [
      {
        id: 501,
        title: "교통량 입력",
        path: "/traffic-report",
        // icon: <BsCheckCircle />,
      },
    ],
  },

  {
    id: 6,
    // title: "동상방지충",
    icon: <PiCaretDown size={14} />,
    items: [
      {
        id: 601,
        title: "동상 방지층 설계",
        path: "/frostbite-prevention",
        // icon: <BsCheckCircle />,
      },
    ],
  },
  {
    id: 7,
    // title: "공용성 분석",
    icon: <PiCaretDown size={14} />,
    items: [
      {
        id: 701,
        title: "공용성 예측",
        path: "/commonality-prediction",
        // icon: <BsCheckCircle />,
      },
      {
        id: 702,
        title: "결과 보고서 확인",
        path: "/result-report",
        // icon: <BsCheckCircle />,
      },
    ],
  },
];
