import useModalStore from "../../store/useModalStore";
import { icons } from "../../contents/icons";

/* 
title 옆 info 아이콘 모달 포지션 및 컬러
*/

const InfoIcon = ({ iconId, content }) => {
  const { openModal, clickedIcon } = useModalStore();

  const handleIconClick = (e) => {
    const buttonRect = e.currentTarget.getBoundingClientRect();
    const modalPosition = {
      top: buttonRect.bottom + window.scrollY,
      left: buttonRect.left + window.scrollX,
    };
    openModal(modalPosition, content, iconId);
  };

  // 모달창 전체적용
  // const handleIconClick = (e) => {
  //   const buttonRect = e.currentTarget.getBoundingClientRect();
  //   let top = buttonRect.bottom + window.scrollY;
  //   let left = buttonRect.left + window.scrollX;

  //   const modalHeight = 200;
  //   const viewportHeight = window.innerHeight;

  //   if (top + modalHeight > viewportHeight) {
  //     top = viewportHeight - modalHeight - 10;
  //   }

  //   openModal({ top, left }, content, iconId);
  // };

  return (
    <icons.BsInfoCircle
      style={{ color: clickedIcon === iconId ? "0066FF" : "black" }}
      onClick={handleIconClick}
    />
  );
};

export default InfoIcon;
