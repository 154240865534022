import React from "react";
import Main from "../../layouts/main/Main";
import Section1 from "../../layouts/main/section1/index";
import style from "./commonalityPrediction.module.css";
import commonality from "../../assets/img/commonality.png";
import Card from "../../components/Card/Card";
import Title from "../../components/Title/Title";
import Line from "../../components/Line/Line";
import InfoIcon from "../../components/InfoIcon/InfoIcon";
import Modal from "../../components/Modal/Modal";

const CommonalityPrediction = () => {
  return (
    <Main>
      <div className={style.commonalityWrap}>
        <Section1></Section1>
        <div className={style.commonalityContainer}>
          <div className={style.containerLeft}>
            <Card>
              <Title
                title="공용성 해석 - 피로균열"
                icon={
                  <InfoIcon
                    iconId="commonalityIcon"
                    content={
                      <p>
                        ※ JCP의 피로균열은 공용기간 20년 동안 20%이내로
                        발생하여야 하며 IRI의 경우 공용기간 20년 기준 4 m/km보다
                        작아야 한다.
                      </p>
                    }
                  />
                }
              />
              <Line />
              <div className={style.CommonalityChartL}>
                <img src={commonality}></img>
                <p>
                  피로균열은 설계기준 20.00% 에 만족하였습니다.
                  <br /> 공용기간 20년 동안 5.11% 균열을 발생
                </p>
              </div>
            </Card>
          </div>
          <div className={style.containerRight}>
            <Card>
              <div className={style.commonalityTitleWrap}>
                <Title title="공용성 해석 - IRI" />
              </div>
              <Line />
              <div className={style.CommonalityChartR}>
                <img src={commonality}></img>
                <p>
                  평탄성지수 (IRI) 설계기준 4.00 m/km에 만족하였습니다.
                  <br />
                  공용기간 20년동안 2.70m/km의 평탄성지수증가.
                </p>
              </div>
            </Card>
          </div>
        </div>
      </div>
      <Modal />
    </Main>
  );
};

export default CommonalityPrediction;
