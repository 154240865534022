import React from "react";
import style from "./index.module.css";
import Title from "../../../components/Title/Title";
import Card from "../../../components/Card/Card";
import Line from "../../../components/Line/Line";

const Section2 = ({ children, style: customStyle, title, icon }) => {
  return (
    <Card>
      <Title title={title} style={customStyle} icon={icon} />
      <Line></Line>
      <div className={style.props}>{children}</div>
    </Card>
  );
};

export default Section2;
