import React from "react";
import style from "./Tabs.module.css";
import img1 from "../../assets/img/task-info-img1.png";

import Title from "../Title/Title";
import Line from "../Line/Line";
import InfoIcon from "../InfoIcon/InfoIcon";

import useTabStore from "../../store/useTabStore";
import tabData from "../../data/tabData";

import { icons } from "../../contents/icons";

import VehicleTypeTable from "../Table/VehicleTypeTable";
import MonthlyTrafficTable from "../Table/MonthlyTrafficTable";
import HourlyTrafficTable from "../Table/HourlyTrafficTable";
import TrafficAnalysisTable from "../Table/TrafficAnalysisTable";

import VehicleTypeImg from "../../assets/img/modal/trafficReport_VehicleTypeTable-min.png";
import MonthlyTrafficImg from "../../assets/img/modal/trafficReport_MonthlyTrafficTable-min.png";
import HourlyTrafficImg from "../../assets/img/modal/trafficReport_HourlyTrafficTable-min.png";
import TrafficAnalysisImg from "../../assets/img/modal/trafficReport_TrafficAnalysisTable-min.png";

const TrafficTabs = () => {
  const { activeTab, setActiveTab } = useTabStore();

  const getTabImage = (tab) => {
    switch (tab) {
      case "차종별 교통량 및 비율":
        return VehicleTypeImg;
      case "월별 교통량 비율":
        return MonthlyTrafficImg;
      case "시간별 교통량 비율":
        return HourlyTrafficImg;
      case "교통하중 분석결과":
        return TrafficAnalysisImg;
      default:
        return VehicleTypeImg;
    }
  };

  const renderTable = () => {
    switch (activeTab) {
      case "차종별 교통량 및 비율":
        return <VehicleTypeTable />;
      case "월별 교통량 비율":
        return <MonthlyTrafficTable />;
      case "시간별 교통량 비율":
        return <HourlyTrafficTable />;
      case "교통하중 분석결과":
        return <TrafficAnalysisTable />;
      default:
        return <p>선택된 탭에 대한 데이터가 없습니다.</p>;
    }
  };

  return (
    <div>
      <div className={style.tabList}>
        {Object.keys(tabData).map((tab) => (
          <button
            key={tab}
            className={`${style.button} ${
              activeTab === tab ? style.active : ""
            }`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
      </div>

      <div className={style.tabPanel}>
        <div className={style.tabMainTable}>
          <div className={style.tabTitle}>
            <Title
              title={activeTab}
              icon={
                <InfoIcon
                  iconId={
                    activeTab === "차종별 교통량 및 비율"
                      ? "trafficVolume"
                      : activeTab === "월별 교통량 비율"
                      ? "monthlyTraffic"
                      : activeTab === "시간별 교통량 비율"
                      ? "hourlyTraffic"
                      : "trafficLoad"
                  }
                  content={
                    <img
                      src={getTabImage(activeTab)}
                      alt={`${activeTab} 이미지`}
                    />
                  }
                />
              }
            />
            <p className={style.resetBtn}>
              <icons.BiRevision />
            </p>
          </div>
          <Line />
          <div className={style.tableMainContent}>{renderTable()}</div>
        </div>
      </div>
    </div>
  );
};

export default TrafficTabs;
