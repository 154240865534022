import React from "react";
import style from "./login.module.css";
import ButtonStyle from "../../components/Button/Button.module.css";

import { useNavigate } from "react-router-dom";

import logo from "../../assets/img/header_logo.png";
import Button from "../../components/Button/Button";

const Login = () => {
  const navigator = useNavigate();
  const handleLocation = (e) => {
    e.preventDefault();
    navigator("/select-page");
  };

  return (
    <div className={style.loginWrap}>
      <div className={style.loginContainer}>
        <form>
          <div className={style.formWrap}>
            <div className={style.titleWrap}>
              <div className={style.logoWrap}>
                <img src={logo} alt="logo"></img>
                <h1>한국형 고속도로 포장 설계</h1>
              </div>
            </div>
            <legend>
              <label className={style.id}>아이디</label>
              <input type="text" placeholder="아이디를 입력해주세요."></input>
            </legend>
            <legend>
              <label className={style.password}>비밀번호</label>
              <input
                type="password"
                placeholder="비밀번호를 입력해주세요."
              ></input>
            </legend>

            <Button className={ButtonStyle.login} onclick={handleLocation()}>
              로그인
            </Button>
            <div className={style.help}>도움이 필요하신가요?</div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
