import React from "react";
import style from "./discontinuousSurface.module.css";

import Main from "../../layouts/main/Main";
import Section1 from "../../layouts/main/section1";
import Section2 from "../../layouts/main/section2";

import Modal from "../../components/Modal/Modal";
import { InputBasic } from "../../components/Input/Input";
import InfoIcon from "../../components/InfoIcon/InfoIcon";

import useTaskStore from "../../store/useTaskStore";

import concreteDrawingImg from "../../assets/img/Concreate_Img.png";
import Tie_Bar from "../../assets/img/modal/discontinuous-surface_Tie_Bar-min.png";
import Dowel_Bar from "../../assets/img/modal/discontinuous-surface_Dowel_Bar-min.png";

const DiscontinuousSurface = () => {
  const {
    TieBar,
    DowelBar,
    setTieBarDiameter,
    setTieBarLength,
    setTieBarSpacing,
    setDowelBarDiameter,
    setDowelBarLength,
    setDowelBarSpacing,
  } = useTaskStore();

  const handleBasicInputChange = (setter) => (e) => {
    setter(e.target.value);
  };

  return (
    <Main>
      <div className={style.surfaceWrap}>
        <Section1 />
        <div className={style.surfaceContentsContainer}>
          <div className={style.containerTop}>
            <div className={style.jointSpaceWidth}>
              <Section2 title="줄눈">
                <InputBasic
                  label="줄눈간격(mm)"
                  name="Joint_Space_Width"
                  type="number"
                  onChange={(e) => handleBasicInputChange(e)}
                />
              </Section2>
            </div>
            <div className={style.tieBar}>
              <Section2
                title="타이바"
                name="Tie_Bar"
                icon={
                  <InfoIcon
                    iconId="Tie_Bar"
                    content={<img src={Tie_Bar} alt="타이바" />}
                  />
                }
              >
                <div className={style.inputWrap}>
                  <InputBasic
                    label="지름(mm)"
                    name="Diameter"
                    value={TieBar.diameter}
                    onChange={handleBasicInputChange(setTieBarDiameter)}
                  />
                  <InputBasic
                    label="길이(mm)"
                    name="Length"
                    value={TieBar.length}
                    onChange={handleBasicInputChange(setTieBarLength)}
                  />
                  <InputBasic
                    label="간격(mm)"
                    name="Spacing"
                    value={TieBar.spacing}
                    onChange={handleBasicInputChange(setTieBarSpacing)}
                  />
                </div>
              </Section2>
            </div>
            <div className={style.dowelBar}>
              <Section2
                title="다웰바"
                name="Dowel_Bar"
                icon={
                  <InfoIcon
                    iconId="Dowel_Bar"
                    content={<img src={Dowel_Bar} alt="디젤바" />}
                  />
                }
              >
                <div className={style.inputWrap}>
                  <InputBasic
                    label="지름(mm)"
                    name="Diameter"
                    value={DowelBar.diameter}
                    onChange={handleBasicInputChange(setDowelBarDiameter)}
                  />
                  <InputBasic
                    label="길이(mm)"
                    name="Length"
                    value={DowelBar.length}
                    onChange={handleBasicInputChange(setDowelBarLength)}
                  />
                  <InputBasic
                    label="간격(mm)"
                    name="Spacing"
                    value={DowelBar.spacing}
                    onChange={handleBasicInputChange(setDowelBarSpacing)}
                  />
                </div>
              </Section2>
            </div>
          </div>
          <div className={style.containerBottom}>
            <div className={style.concreteDrawing}>
              <Section2 title="콘크리트 줄눈 일반도">
                <div className={style.concreteDrawingImg}>
                  <img src={concreteDrawingImg} alt="concreteDrawing"></img>
                </div>
              </Section2>
            </div>
          </div>
        </div>
      </div>
      <Modal />
    </Main>
  );
};

export default DiscontinuousSurface;
