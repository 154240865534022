import React from "react";
import Main from "../../layouts/main/Main";
import Section1 from "../../layouts/main/section1";
import style from "./resultReport.module.css";
import Card from "../../components/Card/Card";
import Title from "../../components/Title/Title";
import Line from "../../components/Line/Line";

import leftImg from "../../assets/img/report_Left_Img.png";
import rightImg from "../../assets/img/report_Right_Img.png";

const ResultReport = () => {
  return (
    <Main>
      <div className={style.reportWrap}>
        <Section1></Section1>
        <div className={style.resultReportContainer}>
          <div className={style.resultReportWrap}>
            <Card>
              <Title title="결과보고서" />
              <Line />
              <div className={style.contents}>
                <div className={style.leftContents}>
                  <div className={style.resultListBoxWrap}>
                    {Array.from({ length: 20 }).map((_, index) => (
                      <div className={style.resultListBox}>
                        <div key={index}>
                          <div className={style.boxItem}>
                            <img src={leftImg}></img>
                          </div>
                          <p>{index + 1}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={style.rightContents}>
                  <div className={style.reportRightImg}>
                    <img src={rightImg}></img>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default ResultReport;
