import { create } from "zustand";

const useSectionStore = create((set) => ({
  dimensions: {
    laneWidth: "3.00",
    shoulderWidth: "1.50",
    slabThickness: "300",
    leanConcreteThickness: "150",
    subbaseLayerThickness: "150",
  },
  setDimension: (name, value) =>
    set((state) => {
      const dimensionKey = Object.keys(state.dimensions).find(
        (key) => key.toLowerCase() === name.toLowerCase().replace(/_/g, "")
      );

      if (dimensionKey) {
        return {
          dimensions: {
            ...state.dimensions,
            [dimensionKey]: value === "" ? "0" : value,
          },
        };
      }
      return state;
    }),
}));

export default useSectionStore;
