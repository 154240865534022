import React from "react";
import style from "./Table.module.css";

const TrafficAnalysisTable = () => {
  const months = [
    "1월",
    "2월",
    "3월",
    "4월",
    "5월",
    "6월",
    "7월",
    "8월",
    "9월",
    "10월",
    "11월",
    "12월",
  ];
  const curlingTypes = ["상향컬링", "컬링없음", "하향컬링"];
  const vehicleTypes = Array.from({ length: 12 }, (_, i) => `${i + 1}종`);

  const dummyData = {
    상향컬링: Array(12).fill("-"),
    컬링없음: Array(12)
      .fill(1000)
      .map((v, i) => v + i * 100),
    하향컬링: Array(12)
      .fill(2000)
      .map((v, i) => v + i * 200),
  };

  return (
    <table className={style.table} style={{ fontSize: "12px" }}>
      <thead>
        <tr>
          <th rowSpan={2}>구분</th>
          <th rowSpan={2}>컬링구분</th>
          {vehicleTypes.map((type) => (
            <th key={type} colSpan={2}>
              {type}
            </th>
          ))}
        </tr>
        <tr>
          {vehicleTypes.map((type) => (
            <React.Fragment key={type}>
              <th>단측단륜</th>
              <th>단측복륜</th>
            </React.Fragment>
          ))}
        </tr>
      </thead>
      <tbody>
        {months.map((month) =>
          curlingTypes.map((curlingType, curlingIndex) => (
            <tr key={`${month}-${curlingType}`}>
              {curlingIndex === 0 && <th rowSpan={3}>{month}</th>}
              <td>{curlingType}</td>
              {vehicleTypes.map((_, typeIndex) => (
                <React.Fragment key={`${month}-${curlingType}-${typeIndex}`}>
                  <td>{dummyData[curlingType][typeIndex]}</td>
                  <td>{dummyData[curlingType][typeIndex]}</td>
                </React.Fragment>
              ))}
            </tr>
          ))
        )}
      </tbody>
    </table>
  );
};

export default TrafficAnalysisTable;
