import React from "react";
import Main from "../../layouts/main/Main";
import style from "./sectionSetting.module.css";

import Section1 from "../../layouts/main/section1";
import Section2 from "../../layouts/main/section2";
import InfoIcon from "../../components/InfoIcon/InfoIcon";
import Modal from "../../components/Modal/Modal";
import {
  InputAddText,
  InputBasic,
  InputDrop,
} from "../../components/Input/Input";
import PavementThicknessSection from "../../components/PavementThicknessSection/PavementThicknessSection";

import useSectionStore from "../../store/useSectionStore";
import useLoadImgStore from "../../store/useLoadImgStore";

import loadImg from "../../assets/img/loadImg.png";
import img4 from "../../assets/img/Vector.png";
import img6 from "../../assets/img/Vector.png";
import img7 from "../../assets/img/Vector.png";

import ThicknessSection from "../../assets/img/modal/sectionSetting_ThicknessSection-min.png";
import sectionSetting from "../../assets/img/modal/sectionSetting_section-setting-min.png";

const SectionSetting = () => {
  const { dimensions, setDimension } = useSectionStore();
  const { selectedLaneCount, setSelectedLaneCount } = useLoadImgStore();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDimension(name, parseFloat(value));
  };
  // console.log("Dimension:", dimensions);

  const handleLaneCountChange = (e) => {
    setSelectedLaneCount(e.target.value);
  };

  console.log("Selected Lane Count:", selectedLaneCount);

  const getLoadImage = () => {
    switch (selectedLaneCount) {
      case "1":
        return loadImg;
      case "2":
        return img4;
      case "3":
        return img6;
      default:
        return img7;
    }
  };

  return (
    <Main>
      <div className={style.sectionSettingWrap}>
        <Section1></Section1>
        <div className={style.sectionContentsContainer}>
          <div className={style.leftContents}>
            <div className={style.sectionSection}>
              <Section2
                title="평면설정"
                icon={
                  <InfoIcon
                    iconId="
              section-setting"
                    content={<img src={sectionSetting} alt="평면설정" />}
                  />
                }
              >
                <div className={style.inputWrap}>
                  <InputDrop
                    label="편도 차로수"
                    name="Number_of_lanes"
                    options={[
                      { label: "1", value: "1" },
                      { label: "2", value: "2" },
                      { label: "3", value: "3" },
                    ]}
                    onChange={handleLaneCountChange}
                  />
                  <InputAddText
                    label="차로폭(m)"
                    name="Lane_Width"
                    type="number"
                    text="※ 3.25 이상"
                    min={3.25}
                    max={undefined}
                    value={dimensions.laneWidth}
                    onChange={handleInputChange}
                  />
                </div>
                <div className={style.inputWrap}>
                  <InputDrop
                    label="길어깨 종류"
                    name="Type_of_Shoulder"
                    options={[
                      {
                        label: "아스팔트 콘크리트",
                        value: "아스팔트 콘크리트",
                      },
                      { label: "시멘트 콘크리트", value: "시멘트 콘크리트" },
                    ]}
                    href="#"
                  />
                  <InputBasic
                    label="길어깨 폭(m)"
                    name="Shoulder_Width"
                    type="number"
                    value={dimensions.shoulderWidth}
                    onChange={handleInputChange}
                  />
                </div>
                <div className={style.loadImg}>
                  <div className={style.img}>
                    <img
                      src={getLoadImage()}
                      alt="Road"
                      className={style.roadImage}
                    />
                    <div className={style.dimensions}>
                      <span className={style.dimension}>
                        {isNaN(parseFloat(dimensions.shoulderWidth))
                          ? "0"
                          : dimensions.shoulderWidth}
                      </span>
                      <span className={style.dimension}>
                        {isNaN(parseFloat(dimensions.laneWidth))
                          ? "0"
                          : dimensions.laneWidth}
                      </span>
                      <span className={style.dimension}>
                        {isNaN(parseFloat(dimensions.laneWidth))
                          ? "0"
                          : dimensions.laneWidth}
                      </span>
                      <span className={style.dimension}>
                        {isNaN(parseFloat(dimensions.laneWidth))
                          ? "0"
                          : dimensions.laneWidth}
                      </span>
                      <span className={style.dimension}>
                        {isNaN(parseFloat(dimensions.laneWidth))
                          ? "0"
                          : dimensions.laneWidth}
                      </span>
                      <span className={style.dimension}>
                        {isNaN(parseFloat(dimensions.shoulderWidth))
                          ? "0"
                          : dimensions.shoulderWidth}
                      </span>
                    </div>
                  </div>
                </div>
              </Section2>
            </div>
          </div>
          <div className={style.rightContents}>
            <div className={style.protectiveLayer}>
              <Section2
                title="포장층 두께"
                icon={
                  <InfoIcon
                    iconId="ThicknessSection"
                    content={<img src={ThicknessSection} alt="평면설정" />}
                  />
                }
              >
                <div className={style.inputWrap}>
                  <InputAddText
                    label="슬래브(mm)"
                    type="number"
                    name="Slab_Thickness"
                    min={280}
                    max={320}
                    value={dimensions.slabThickness}
                    onChange={handleInputChange}
                  />
                  <InputAddText
                    label="린콘크리트(mm)"
                    type="number"
                    min={280}
                    max={320}
                    name="Lean_Concrete_Thickness"
                    value={dimensions.leanConcreteThickness}
                    onChange={handleInputChange}
                  />
                </div>
                <div className={style.inputWrap}>
                  <InputAddText
                    label="보조기층(mm)"
                    type="number"
                    min={150}
                    max={400}
                    name="Subbase_Layer_Thickness"
                    value={dimensions.subbaseLayerThickness}
                    onChange={handleInputChange}
                  />
                  <InputBasic label="노상" readOnly disabled="disabled" />
                </div>
                <div className={style.loadImg}>
                  <PavementThicknessSection
                    dimensions={dimensions}
                    handleInputChange={handleInputChange}
                  />
                </div>
              </Section2>
            </div>
          </div>
        </div>
      </div>
      <Modal></Modal>
    </Main>
  );
};

export default SectionSetting;
