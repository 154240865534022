import "./App.css";
import "./index.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import Login from "./pages/login/Login";
import TaskInfo from "./pages/taskInfo/TaskInfo";
import SectionSetting from "./pages/sectionSetting/SectionSetting";
import Material from "./pages/material/Material";
import DiscontinuousSurface from "./pages/discontinuousSurface/DiscontinuousSurface";
import LongitudinalDirectionRebar from "./pages/longitudinalDirectionRebar/LongitudinalDirectionRebar";
import TrafficReport from "./pages/trafficReport/TrafficReport";

import SelectPage from "./pages/selectPage/SelectPage";
import WeatherInfo from "./pages/weatherInfo/WeatherInfo";
import FrostbitePrevention from "./pages/frostbitePrevention/FrostbitePrevention";
import CommonalityPrediction from "./pages/commonalityPrediction/CommonalityPrediction";
import ResultReport from "./pages/resultReport/ResultReport";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/select-page" element={<SelectPage />} />
          <Route path="/task-info" element={<TaskInfo />} />
          <Route path="/section-setting" element={<SectionSetting />} />
          <Route path="/material" element={<Material />} />
          <Route path="/traffic-report" element={<TrafficReport />} />
          <Route
            path="/discontinuous-surface"
            element={<DiscontinuousSurface />}
          />
          <Route
            path="/longitudinal-DirectionRebar"
            element={<LongitudinalDirectionRebar />}
          />
          <Route path="/weather-info" element={<WeatherInfo />} />
          <Route
            path="/frostbite-prevention"
            element={<FrostbitePrevention />}
          />
          <Route
            path="/commonality-prediction"
            element={<CommonalityPrediction />}
          />
          <Route path="/result-report" element={<ResultReport />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
