import React from "react";
import style from "./header.module.css";
import { useNavigate } from "react-router-dom";

import { faBars } from "@fortawesome/free-solid-svg-icons";
import { PiFileText, PiFolderOpen, PiList } from "react-icons/pi";

import hederLoge from "../../assets/img/header_logo.png";

const Header = () => {
  const navigator = useNavigate();

  const handleMoveLoginPage = () => {
    navigator("/login");
  };

  return (
    <>
      <header id={style.header}>
        <div className={style.headerWrap}>
          <div className={style.logo} onClick={handleMoveLoginPage}>
            {/* <div className={style.hamburgerMenu}>
              <PiList icon={faBars} />
            </div> */}
            <img className={style.img} src={hederLoge} alt="logo" />
            <p>한국형 고속도로 포장 설계</p>
          </div>
          <div className={style.menuWrap}>
            {/* <div className={style.file}>
              <p>
                <PiFileText />
              </p>
              <p>새파일</p>
            </div>
            <div className={style.fileOpen}>
              <p>
                <PiFolderOpen />
              </p>
              <p>파일열기</p>
            </div> */}
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
