import React from "react";
import style from "./main.module.css";

import Header from "../header/Header";
import Nav from "../nav/Nav";

const Main = ({ children }) => {
  return (
    <div>
      <Header />
      <div className={style.container}>
        <Nav />
        <main id={style.main}>{children}</main>
      </div>
    </div>
  );
};

export default Main;
