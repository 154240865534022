import { useState } from "react";
import Main from "../../layouts/main/Main";
import style from "./taskInfo.module.css";

import Section1 from "../../layouts/main/section1";
import Section2 from "../../layouts/main/section2";
import {
  InputBasic,
  InputDate,
  InputDrop,
  InputDropUp,
} from "../../components/Input/Input";
import InfoIcon from "../../components/InfoIcon/InfoIcon";
import Modal from "../../components/Modal/Modal";

import useTaskStore from "../../store/useTaskStore";

import mapImg from "../../assets/img/task-info_map.png";
import PackagingSelect from "../../assets/img/modal/taskInfo_Packaging_select-min.png";
import taskInfoReliability from "../../assets/img/modal/taskInfo_Reliability-min.png";
import taskInfoDesign from "../../assets/img/modal/taskInfo_Design-info-min.png";

const TaskInfo = () => {
  const {
    routeName,
    setRouteName,
    projectSection,
    setProjectSection,
    designInstitute,
    setDesignInstitute,

    servicePeriod,
    setServicePeriod,
    setTitleColor,
    fatigueCrack,
    setFatigueCrack,
    IRI,
    setIRI,
    IRIDesignCriteria,
    setIRIDesignCriteria,
    reliability,
    setReliability,
    punchOut,
    setPunchOut,
    titleColor,
  } = useTaskStore();
  const [designDate, setDesignDate] = useState(null);
  const [trafficOpeningDate, setTrafficOpeningDate] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    setTitleColor("black");
  };

  const handleDropdownChange = (option) => {
    setSelectedOption(option);
  };
  return (
    <Main>
      <div className={style.taskWrap}>
        <Section1 />
        <div className={style.taskContentsContainer}>
          <div className={style.leftContents}>
            <div className={style.basicInfoWr}>
              <Section2 title="기본정보">
                <InputBasic
                  label="노선명"
                  name="Route_Name"
                  customStyle={{ color: titleColor }}
                  value={routeName}
                  onChange={handleInputChange(setRouteName)}
                />
                <InputBasic
                  label="공구명"
                  name="Project_Section"
                  customStyle={{ color: titleColor }}
                  value={projectSection}
                  onChange={handleInputChange(setProjectSection)}
                />
                <InputBasic
                  label="설계기관"
                  name="Design_Institute"
                  customStyle={{ color: titleColor }}
                  value={designInstitute}
                  onChange={handleInputChange(setDesignInstitute)}
                />
                <div className={style.inputWrap}>
                  <InputDate
                    label="설계일자"
                    name="Design_Date"
                    type="number"
                    selectedDate={designDate}
                    onChange={setDesignDate}
                    maxDate={trafficOpeningDate || new Date()}
                  />
                  <InputDate
                    label="교통개방일자"
                    name="Traffic_Opening_Date"
                    selectedDate={trafficOpeningDate}
                    onChange={setTrafficOpeningDate}
                    minDate={designDate}
                  />
                </div>
              </Section2>
            </div>
            <div className={style.modelInfoWrap}>
              <Section2
                title="설계등급 및 포장선택"
                icon={
                  <InfoIcon
                    iconId="Packaging-select"
                    content={
                      <img
                        src={PackagingSelect}
                        alt="설계정보 및 포장선택 이미지"
                      />
                    }
                  />
                }
              >
                <div className={style.inputWrap}>
                  <InputBasic
                    label="공용기간(년)"
                    type="number"
                    name="Service_Period"
                    customStyle={{ width: "117px" }}
                    value={servicePeriod}
                    onChange={handleInputChange(setServicePeriod)}
                  />
                  <InputDrop
                    label="설계등급"
                    name="Design_Grade"
                    options={[
                      { label: "1등급", value: "1등급" },
                      { label: "2등급", value: "2등급" },
                    ]}
                    customStyle={{ width: "117px" }}
                    onChange={handleDropdownChange}
                  />
                  <InputDrop
                    label="포장형식"
                    name="Pavement_type"
                    options={[
                      {
                        label: "줄눈콘크리트 포장 (JCP)",
                        value: "Jointed_Concrete_Pavement",
                      },
                      {
                        label: "연속철근콘크리트 포장 (CRCP)",
                        value: "Continuously_Reinforced_Concrete_Pavement",
                      },
                      { label: "터널 포장 (TP)", value: "Tunnel_Pavement" },
                    ]}
                    customStyle={{ width: "240px" }}
                  />
                </div>
              </Section2>
            </div>

            <div className={style.reliabilityWrap}>
              <Section2
                title="설계 공용성 및 신뢰도"
                icon={
                  <InfoIcon
                    iconId="
                  reliability"
                    content={
                      <img
                        src={taskInfoReliability}
                        alt="설계 공용성 및 신뢰도 이미지"
                      />
                    }
                  />
                }
              >
                <div className={style.inputWrap}>
                  <InputBasic
                    label="피로균열 (Fatigue Crack, %)"
                    name="Fatigue_Crack"
                    type="number"
                    value={fatigueCrack}
                    onChange={handleInputChange(setFatigueCrack)}
                  />
                  <InputBasic
                    label="평탄성지수 초기값(IRIom/km)"
                    name="IRI"
                    type="number"
                    disabled="disabled"
                    value={IRI}
                    onChange={handleInputChange(setIRI)}
                  />
                </div>
                <div className={style.inputWrap}>
                  <InputBasic
                    label="평탄성지수 설계기준(IRIm.km)"
                    name="IRI_Design_Criteria"
                    customStyle={{ width: "200px" }}
                    type="number"
                    value={IRIDesignCriteria}
                    onChange={handleInputChange(setIRIDesignCriteria)}
                  />
                  <InputBasic
                    label="신뢰도(%)"
                    name="Reliability"
                    type="number"
                    disabled="disabled"
                    value={reliability}
                    onChange={handleInputChange(setReliability)}
                  />
                  <InputBasic
                    label="펀치아웃(개수/km)"
                    name="Punch_Out"
                    type="number"
                    // disabled="disabled"
                    value={punchOut}
                    onChange={handleInputChange(setPunchOut)}
                  />
                </div>
              </Section2>
            </div>
          </div>
          <div className={style.rightContents}>
            <Section2
              title="설계정보"
              icon={
                <InfoIcon
                  iconId="design-info"
                  content={<img src={taskInfoDesign} alt="설계정보 이미지" />}
                />
              }
            >
              <div className={style.map}>
                <img src={mapImg}></img>
              </div>
              <div className={style.mapContents}>
                <div className={style.mapStarting} name="Starting_Point">
                  <InputBasic
                    label="시점"
                    name="Latitude"
                    placeholder="위도를 입력해주세요."
                    onChange={(e) => handleInputChange(e)}
                  />
                  <InputBasic
                    name="Longitude"
                    placeholder="경도를 입력해주세요."
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
                <div className={style.mapEnding} name="Ending_Point">
                  <InputBasic
                    label="종점"
                    name="Latitude"
                    placeholder="위도를 입력해주세요."
                    onChange={(e) => handleInputChange(e)}
                  />
                  <InputBasic
                    name="Longitude"
                    placeholder="경도를 입력해주세요."
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
              </div>
              <div className={style.mapContents}>
                <InputBasic
                  label="연장(km)"
                  name="Road_Length"
                  placeholder="연장(km)을 입력해주세요."
                  onChange={(e) => handleInputChange(e)}
                />
                <InputBasic
                  label="설계구간 최대표고(m)"
                  name="Average_Elevation"
                  placeholder="평균표고를 입력해주세요."
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
              <div className={style.mapContents}>
                <InputDropUp
                  label="지역구분"
                  name="Region_classification"
                  options={[
                    { label: "도시지역", value: "option1" },
                    { label: "지방지역", value: "option2" },
                  ]}
                />
                <InputDropUp
                  label="설계속도(km/h)"
                  name="Design_Speed"
                  options={[
                    { label: "80", value: "option1" },
                    { label: "90", value: "option2" },
                    { label: "100", value: "option3" },
                    { label: "110", value: "option4" },
                    { label: "120", value: "option5" },
                  ]}
                />
              </div>
            </Section2>
          </div>
        </div>
      </div>
      <Modal />
    </Main>
  );
};

export default TaskInfo;
