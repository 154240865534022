import React, { useState } from "react";
import style from "./Tabs.module.css";
import gr01 from "../../assets/img/weatherGf_01.png";
import gr02 from "../../assets/img/weatherGf_05.png";
import gr03 from "../../assets/img/weatherGf_04.png";

const WeatherTabs = () => {
  const [activeTab, setActiveTab] = useState("기온");

  const tabData = {
    기온: (
      <div>
        <img
          className="tabImg"
          alt="img"
          style={{ height: "330px" }}
          src={gr01}
        ></img>
      </div>
    ),
    강수량: (
      <div>
        <img
          className="tabImg"
          alt="img"
          style={{ height: "330px" }}
          src={gr02}
        ></img>
      </div>
    ),
    포장층온도분석결과: (
      <div cl>
        <img
          className="tabImg"
          alt="img"
          style={{ height: "330px" }}
          src={gr03}
        ></img>
      </div>
    ),
    컬링주기: (
      <div className={style.tabsTable}>
        <table>
          <thead>
            <tr>
              <th rowSpan="2">구분</th>
              <th colSpan="2">상향 컬링</th>
              <th colSpan="2">컬링 없음</th>
              <th colSpan="2">하향 컬링</th>
              <th colSpan="2">컬링 없음</th>
            </tr>
            <tr>
              <th>시작시간</th>
              <th>종료시간</th>
              <th>시작시간</th>
              <th>종료시간</th>
              <th>시작시간</th>
              <th>종료시간</th>
              <th>시작시간</th>
              <th>종료시간</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1월</td>
              <td>23</td>
              <td>9</td>
              <td>9</td>
              <td>11</td>
              <td>11</td>
              <td>19</td>
              <td>19</td>
              <td>23</td>
            </tr>
            <tr>
              <td>2월</td>
              <td>22</td>
              <td>9</td>
              <td>9</td>
              <td>11</td>
              <td>11</td>
              <td>19</td>
              <td>19</td>
              <td>22</td>
            </tr>
            <tr>
              <td>3월</td>
              <td>19</td>
              <td>8</td>
              <td>8</td>
              <td>10</td>
              <td>10</td>
              <td>17</td>
              <td>17</td>
              <td>19</td>
            </tr>
            <tr>
              <td>4월</td>
              <td>20</td>
              <td>8</td>
              <td>8</td>
              <td>9</td>
              <td>9</td>
              <td>18</td>
              <td>18</td>
              <td>20</td>
            </tr>
            <tr>
              <td>5월</td>
              <td>20</td>
              <td>8</td>
              <td>8</td>
              <td>9</td>
              <td>9</td>
              <td>18</td>
              <td>18</td>
              <td>20</td>
            </tr>
            <tr>
              <td>6월</td>
              <td>22</td>
              <td>8</td>
              <td>8</td>
              <td>9</td>
              <td>9</td>
              <td>20</td>
              <td>20</td>
              <td>22</td>
            </tr>
            <tr>
              <td>7월</td>
              <td>22</td>
              <td>8</td>
              <td>8</td>
              <td>9</td>
              <td>9</td>
              <td>20</td>
              <td>20</td>
              <td>22</td>
            </tr>
            <tr>
              <td>8월</td>
              <td>21</td>
              <td>8</td>
              <td>8</td>
              <td>9</td>
              <td>9</td>
              <td>20</td>
              <td>20</td>
              <td>21</td>
            </tr>
            <tr>
              <td>9월</td>
              <td>21</td>
              <td>8</td>
              <td>8</td>
              <td>9</td>
              <td>9</td>
              <td>19</td>
              <td>19</td>
              <td>21</td>
            </tr>
            <tr>
              <td>10월</td>
              <td>21</td>
              <td>8</td>
              <td>8</td>
              <td>9</td>
              <td>9</td>
              <td>19</td>
              <td>19</td>
              <td>21</td>
            </tr>
            <tr>
              <td>11월</td>
              <td>21</td>
              <td>8</td>
              <td>8</td>
              <td>9</td>
              <td>9</td>
              <td>19</td>
              <td>19</td>
              <td>21</td>
            </tr>
            <tr>
              <td>12월</td>
              <td>21</td>
              <td>8</td>
              <td>8</td>
              <td>9</td>
              <td>9</td>
              <td>19</td>
              <td>19</td>
              <td>21</td>
            </tr>
          </tbody>
        </table>
      </div>
    ),
  };
  return (
    <div className={style.weatherTabsWrap}>
      <div className={style.tabButton}>
        {Object.keys(tabData).map((tab) => (
          <button
            key={tab}
            className={`${style.tabButton} ${
              activeTab === tab ? style.active : ""
            }`}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}

        <div className={style.tabContent}>{tabData[activeTab]}</div>
      </div>
    </div>
  );
};

export default WeatherTabs;
