import { create } from "zustand";

const useTaskStore = create((set) => ({
  routeName: "노선명",
  setRouteName: (routeName) => set({ routeName }),

  projectSection: "공구명",
  setProjectSection: (projectSection) => set({ projectSection }),

  designInstitute: "설계기관",
  setDesignInstitute: (designInstitute) => set({ designInstitute }),

  titleColor: "gray",
  setTitleColor: (color) => set({ titleColor: color }),

  fatigueCrack: "2.00",
  setFatigueCrack: (fatigueCrack) => set({ fatigueCrack }),

  servicePeriod: "20",
  setServicePeriod: (servicePeriod) => set({ servicePeriod }),

  IRI: "1.41",
  setIRI: (IRI) => set(IRI),

  IRIDesignCriteria: "4.00",
  setIRIDesignCriteria: (IRIDesignCriteria) => set({ IRIDesignCriteria }),

  reliability: "50",
  setReliability: (reliability) => set({ reliability }),

  punchOut: "6",
  setPunchOut: (punchOut) => set({ punchOut }),

  /** 
   재료물성입력 : 슬래브
   **/
  slabUnitWeight: "23.84",
  setSlabUnitWeight: (slabUnitWeight) => set({ slabUnitWeight }),

  slabCoefficientThermalExpansion: "10.70",
  setSlabCoefficientThermalExpansion: (slabCoefficientThermalExpansion) =>
    set({ slabCoefficientThermalExpansion }),

  slabPoissonRatio: "0.15",
  setSlabPoissonRatio: (slabPoissonRatio) => set({ slabPoissonRatio }),

  slabDryingShrinkageCoefficient: "0.000197",
  setSlabDryingShrinkageCoefficient: (slabDryingShrinkageCoefficient) =>
    set({ slabDryingShrinkageCoefficient }),

  slabDayCompressiveStrength: "456.63",
  setSlabDayCompressiveStrength: (slabDayCompressiveStrength) =>
    set({ slabDayCompressiveStrength }),

  slabDayModulusElasticity: "27.01",
  setSlabDayModulusElasticity: (slabDayModulusElasticity) =>
    set({ slabDayModulusElasticity }),

  aNf: "0.97",
  setANf: (aNf) => set({ aNf }),
  bNf: "0.97",
  setBNf: (bNf) => set({ bNf }),
  cNf: "0.97",
  setCNf: (cNf) => set({ cNf }),

  /** 
   재료물성입력 : 린콘크리트층
   **/

  leanUnitWeight: "21.42",
  setLeanUnitWeight: (leanUnitWeight) => set({ leanUnitWeight }),

  leanPoissonRatio: "0.15",
  setLeanPoissonRatio: (leanPoissonRatio) => set({ leanPoissonRatio }),

  leanModulusElasticity: "22.24",
  setLeanModulusElasticity: (leanModulusElasticity) =>
    set({ leanModulusElasticity }),

  /** 
   재료물성입력 : 보조기층
   **/
  subbaseMaximumDryUnitWeight: "21.58",
  setSubbaseMaximumDryUnitWeight: (subbaseMaximumDryUnitWeight) =>
    set({ subbaseMaximumDryUnitWeight }),

  SubbaseUniformityCoefficient: "30",
  setSubbaseUniformityCoefficient: (SubbaseUniformityCoefficient) =>
    set({ SubbaseUniformityCoefficient }),

  subbasePassingRate4: "45",
  setSubbasePassingRate4: (subbasePassingRate4) => set({ subbasePassingRate4 }),

  subbaseOptimumMoistureContent: "6.2",
  setSubbaseOptimumMoistureContent: (subbaseOptimumMoistureContent) =>
    set({ subbaseOptimumMoistureContent }),

  /** 
   재료물성입력 : 노상
   **/

  subgradeMaximumDryUnitWeight: "19.62",
  setSubgradeMaximumDryUnitWeight: (subgradeMaximumDryUnitWeight) =>
    set({ subgradeMaximumDryUnitWeight }),

  subgradeUniformityCoefficient: "14.5",
  setSubgradeUniformityCoefficient: (subgradeUniformityCoefficient) =>
    set({ subgradeUniformityCoefficient }),

  subgradePassingRate2: "9",
  setSubgradePassingRate2: (subgradePassingRate2) =>
    set({ subgradePassingRate2 }),

  subgradePlasticityIndex: "6",
  setSubgradePlasticityIndex: (subgradePlasticityIndex) =>
    set({ subgradePlasticityIndex }),

  subgradeOptimumMoistureContent: "10.5",
  setSubgradeOptimumMoistureContent: (subgradeOptimumMoistureContent) =>
    set({ subgradeOptimumMoistureContent }),

  /** 
  불연속면설계 : TieBar
   **/
  TieBar: {
    diameter: "160",
    length: "800",
    spacing: "750",
  },
  setTieBarDiameter: (diameter) =>
    set((state) => ({
      TieBar: { ...state.TieBar, diameter },
    })),
  setTieBarLength: (length) =>
    set((state) => ({
      TieBar: { ...state.TieBar, length },
    })),
  setTieBarSpacing: (spacing) =>
    set((state) => ({
      TieBar: { ...state.TieBar, spacing },
    })),

  /** 
  불연속면설계 : DowelBar
   **/
  DowelBar: {
    diameter: "25",
    length: "500",
    spacing: "300",
  },
  setDowelBarDiameter: (diameter) =>
    set((state) => ({
      DowelBar: { ...state.DowelBar, diameter },
    })),
  setDowelBarLength: (length) =>
    set((state) => ({
      DowelBar: { ...state.DowelBar, length },
    })),
  setDowelBarSpacing: (spacing) =>
    set((state) => ({
      DowelBar: { ...state.DowelBar, spacing },
    })),

  directionFactor: "0.55",
  setDirectionFactor: (directionFactor) => set({ directionFactor }),

  laneFactor: "0.90",
  setLaneFactor: (laneFactor) => set({ laneFactor }),

  /** 
  동상방지층 설계 
   **/
  embankmentHeight: "1",
  setEmbankmentHeight: (embankmentHeight) => set({ embankmentHeight }),

  frostSensitivity: "9",
  setFrostSensitivity: (frostSensitivity) => set({ frostSensitivity }),

  frostLineElevationDifference: "1",
  setFrostLineElevationDifference: (frostLineElevationDifference) =>
    set({ frostLineElevationDifference }),

  frostProtectionLayerThickness: "0.00",
  setFrostProtectionLayerThickness: (frostProtectionLayerThickness) =>
    set(frostProtectionLayerThickness),
}));

export default useTaskStore;
