import React from "react";
import style from "./frostbitePrevention.module.css";

import Main from "../../layouts/main/Main";
import Section1 from "../../layouts/main/section1";
import Section2 from "../../layouts/main/section2";

import img3 from "../../assets/img/sectionImg.png";

import { InputBasic, InputDrop } from "../../components/Input/Input";
import InfoIcon from "../../components/InfoIcon/InfoIcon";
import Modal from "../../components/Modal/Modal";
import useTaskStore from "../../store/useTaskStore";

const FrostbitePrevention = () => {
  const {
    embankmentHeight,
    setEmbankmentHeight,
    frostSensitivity,
    setFrostSensitivity,
    frostLineElevationDifference,
    setFrostLineElevationDifference,
    frostProtectionLayerThickness,
    setFrostProtectionLayerThickness,
  } = useTaskStore();

  const handleBasicInputChange = (setter) => (e) => {
    setter(e.target.value);
  };

  return (
    <Main>
      <div className={style.frostbiteWrap}>
        <Section1 />
        <div className={style.frostbiteContainer}>
          <div className={style.containerLeft}>
            <div className={style.inSituCondition}>
              <Section2 title="현장조건">
                <div className={style.inputWrap}>
                  <InputBasic
                    label="성토부 높이(m)"
                    name="Embankment_Height"
                    type="number"
                    value={embankmentHeight}
                    onChange={handleBasicInputChange(setEmbankmentHeight)}
                    icon={
                      <InfoIcon
                        iconId="embankmentHeight"
                        content={
                          <p>
                            ※ 성토부 높이가 2m 이상일 경우 동상방지층 불필요
                            <br />
                            <span>
                              도로 동상방지층 설계 지침(국토교통부.2012.pp.25)
                            </span>
                          </p>
                        }
                      />
                    }
                  />
                  <InputDrop
                    label="노상의 특징"
                    name="Subgrade_Characteristics"
                    options={[
                      {
                        label: "토사",
                        value: "토사",
                      },
                      { label: "암반", value: "암반" },
                    ]}
                    icon={
                      <InfoIcon
                        iconId="characteristicsStreet"
                        content={
                          <p>
                            ※ 노상토가 암반인 경우 동상방지층 불필요
                            <span>
                              도로 동상방지층 설계 지침
                              (국토교총부.2012.pp.22~23)
                            </span>
                          </p>
                        }
                      />
                    }
                  />
                </div>
              </Section2>
            </div>
            <div className={style.soilCondition}>
              <Section2 title="토질조건">
                <InputBasic
                  label="동상민감도(%)"
                  name="Frost_Sensitivity"
                  value={frostSensitivity}
                  disabled="disabled"
                  onChange={handleBasicInputChange(setFrostSensitivity)}
                  icon={
                    <InfoIcon
                      iconId="frostbiteSensitivity"
                      content={
                        <p>
                          ※ 0.08mm 통과율 기준 8% 이하의 토지가 노상토일 경우
                          동상방지층 불필요
                          <span>
                            도로 동상방지층 설계 지침(국토교통부.2012.pp.29)
                          </span>
                        </p>
                      }
                    />
                  }
                />
              </Section2>
            </div>
            <div className={style.groundwaterLevelCondition}>
              <Section2 title="지하수위조건">
                <InputBasic
                  label="동상 수위 높이차(m)"
                  name="Frost_Line_Elevation_Difference"
                  type="number"
                  value={frostLineElevationDifference}
                  onChange={handleBasicInputChange(
                    setFrostLineElevationDifference
                  )}
                  icon={
                    <InfoIcon
                      iconId="waterLevel"
                      name="Frost_Line_Elevation_Difference"
                      content={
                        <p>
                          ※ 동상 수위 높이차가 1.5m 이상의 경우 동상방지층
                          불필요
                          <span>
                            도로 동상방지층 설계 지침(국토교통부.2012.pp.27,28)
                          </span>
                        </p>
                      }
                    />
                  }
                />
              </Section2>
            </div>
            <div className={style.layerThickness}>
              <Section2 title="동상방지층 두께 선정">
                <InputBasic
                  label="동상방지층 두께(m)"
                  name="Frost_Protection_Layer_Thickness"
                  type="number"
                  value={frostProtectionLayerThickness}
                  onChange={handleBasicInputChange(
                    setFrostProtectionLayerThickness
                  )}
                />
              </Section2>
            </div>
          </div>
          <div className={style.containerRight}>
            <div className={style.frostDepthCalculation}>
              <Section2 title="동결깊이 산정" name="Frost_Depth_Calculation">
                <div className={style.tableWrap}>
                  <table>
                    <thead>
                      <tr>
                        <th>적용관측소</th>
                        <th>적용표고(m)</th>
                        <th>설계최대표고(m)</th>
                        <th>동결기간(일)</th>
                        <th>동결지수(℃)</th>
                        <th>수정동결지수(℃일)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>서울</th>
                        <td>85.5</td>
                        <td>0</td>
                        <td>68.0</td>
                        <td>278.90</td>
                        <td>249.83</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className={style.inputWrap}>
                  <InputBasic
                    label="최대 동결깊이(m)"
                    name="Maximum_Frost_Depth"
                    disabled="disabled"
                  />
                  <InputBasic
                    label="설계동결깊이(m)"
                    name="Design_Frost_Depth"
                    disabled="disabled"
                    icon={
                      <InfoIcon
                        infoId="freezeDepth"
                        content={
                          <p>
                            ※ 설계 동결 깊이는 노상 동결관입 허용법에 따라
                            최대동결 깊이의 75%를 사용한다.
                            <span>
                              ※ 도로 동상방지층 설계 지침(국토교통부.2012.pp.15)
                            </span>
                          </p>
                        }
                      />
                    }
                  />
                  <InputBasic
                    label="포장두께(m)"
                    name="Pavement_Thickness"
                    disabled="disabled"
                  />
                  <InputBasic
                    label="동상방지층 적용 여부"
                    icon={
                      <InfoIcon
                        infoId="Application_Frost_Prevention"
                        content={
                          <p>
                            ※ 설계 동결 깊이(0.42202m) &lt; 포장 두께(0.6m)
                            이므로 동상방지층 불필요
                            <span>
                              ※ 도로 동상방지층 설계 지침(국토교통부.2012.pp.15)
                            </span>
                          </p>
                        }
                      />
                    }
                    value="필요"
                    disabled="disabled"
                  />
                </div>
              </Section2>
            </div>
            <div className="packagingSection">
              <Section2 title="동상방지층을 포함한 포장단면 개략도">
                <div className={style.sectionImgWrap}>
                  <img src={img3}></img>
                </div>
              </Section2>
            </div>
          </div>
        </div>
      </div>
      <Modal />
    </Main>
  );
};

export default FrostbitePrevention;
