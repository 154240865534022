import React from "react";
import Main from "../../layouts/main/Main";
import Section1 from "../../layouts/main/section1";
import Section2 from "../../layouts/main/section2";
import style from "./longitudinalDirectionRebar.module.css";

const LongitudinalDirectionRebar = () => {
  return (
    <Main>
      <div className={style.rebarWrap}>
        <Section1 />
        <div className={style.longitudinalMuscleContainer}>
          <Section2 title="종방향 철근"></Section2>
        </div>
      </div>
    </Main>
  );
};

export default LongitudinalDirectionRebar;
