import React from "react";
import style from "./trafficReport.module.css";
import Main from "../../layouts/main/Main";
import Section1 from "../../layouts/main/section1";
import Section2 from "../../layouts/main/section2";

import { InputBasic, InputDrop } from "../../components/Input/Input";
import Card from "../../components/Card/Card";
import TrafficTabs from "../../components/Tab/TrafficTabs";
import Modal from "../../components/Modal/Modal";

import useTaskStore from "../../store/useTaskStore";
import InfoIcon from "../../components/InfoIcon/InfoIcon";

import trafficReportForecastMethod from "../../assets/img/modal/trafficReport_Traffic_Volume_FOrecast_Method-min.png";
import trafficVehicleTypeTrafficVolume from "../../assets/img/modal/trafficReport_vehicleTypeTrafficVolume-min.png";
import trafficReportConversionFactor from "../../assets/img/modal/trafficReport_trafficVolumeConversionFactor-min.png";

const TrafficReport = () => {
  const { directionFactor, setDirectionFactor, laneFactor, setLaneFactor } =
    useTaskStore();

  const handleBasicInputChange = (setter) => (e) => {
    setter(e.target.value);
  };

  return (
    <Main>
      <div className={style.trafficWrap}>
        <Section1></Section1>
        <div className={style.trafficDataInputContainer}>
          <div className={style.containerTop}>
            <div className={style.trafficVolumeForecastMethod}>
              <Section2
                title="교통량 예측방법"
                icon={
                  <InfoIcon
                    iconId="Traffic_Volume_Forecast_Method"
                    content={<img src={trafficReportForecastMethod}></img>}
                  />
                }
              >
                <div className={style.inputWrap}>
                  <InputDrop
                    label="교통량 예측방법"
                    name="Traffic_Volume_Forecast_Method"
                    options={[
                      {
                        label: "교통수요예측자료",
                        value: "Traffic_Demand_Forecast_Data",
                      },
                      { label: "선형증가율", value: "Linear_Growth_Rate" },
                      {
                        label: "비선형증가율",
                        value: "Non_Linear_Growth_Rate",
                      },
                    ]}
                  />
                  <InputBasic label="증가율(%)" type="number" />
                </div>
              </Section2>
            </div>
            <div className={style.vehicleTypeTrafficVolume}>
              <Section2
                title="차종별 교통량"
                icon={
                  <InfoIcon
                    iconId="vehicleTypeTrafficVolume"
                    content={<img src={trafficVehicleTypeTrafficVolume}></img>}
                  />
                }
              >
                <InputBasic
                  label="개방년도 AADT(대)"
                  name="AADT_for_The_Opening_Year"
                  type="number"
                />
              </Section2>
            </div>
            <div className={style.trafficVolumeConversionFactor}>
              <Section2
                title="교통량 환산 계수"
                icon={
                  <InfoIcon
                    iconId="AADTforTheOpeningYear"
                    content={<img src={trafficReportConversionFactor}></img>}
                  />
                }
              >
                <div className={style.inputWrap}>
                  <InputBasic
                    label="방향계수"
                    name="Direction_Factor"
                    type="number"
                    value={directionFactor}
                    onChange={handleBasicInputChange(setDirectionFactor)}
                  />
                  <InputBasic
                    label="차로계수"
                    name="Lane_Factor"
                    type="number"
                    value={laneFactor}
                    onChange={handleBasicInputChange(setLaneFactor)}
                  />
                </div>
              </Section2>
            </div>
          </div>
          <div className={style.containerBottom}>
            <div className={style.TabWrap}>
              <Card>
                <TrafficTabs></TrafficTabs>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <Modal />
    </Main>
  );
};

export default TrafficReport;
