import React from "react";
import Main from "../../layouts/main/Main";
import style from "./weatherInfo.module.css";
import Section1 from "../../layouts/main/section1";
import Section2 from "../../layouts/main/section2";
import { InputBasic, InputDrop } from "../../components/Input/Input";
import WeatherTabs from "../../components/Tab/WeatherTabs";

import map from "../../assets/img/weatherMap.png";

const WeatherInfo = () => {
  return (
    <Main>
      <div className={style.weatherWrap}>
        <Section1></Section1>
        <div className={style.weatherContainer}>
          <div className={style.containerLeft}>
            <div className={style.weatherStationSelection}>
              <Section2 title="기상 관측소 선택">
                <div className={style.inputWrap}>
                  <InputDrop
                    label="기상관측소"
                    name="Weather_Section"
                    options={[
                      { label: "강화" },
                      { label: "강릉" },
                      { label: "거제" },
                      { label: "거창" },
                      { label: "고흥" },
                      { label: "광주" },
                      { label: "구미" },
                      { label: "군산" },
                      { label: "금산" },
                      { label: "김천" },
                      { label: "남해" },
                      { label: "남원" },
                      { label: "대구" },
                      { label: "대관령" },
                      { label: "대전" },
                      { label: "동해" },
                      { label: "목포" },
                      { label: "문경" },
                      { label: "밀양" },
                      { label: "보령" },
                      { label: "보은" },
                      { label: "봉화" },
                      { label: "부산" },
                      { label: "부안" },
                      { label: "부여" },
                      { label: "서울" },
                      { label: "서귀포" },
                      { label: "서산" },
                      { label: "성산" },
                      { label: "속초" },
                      { label: "수원" },
                      { label: "순천" },
                      { label: "아산(천안)" },
                      { label: "안동" },
                      { label: "양평" },
                      { label: "여수" },
                      { label: "영덕" },
                      { label: "영주" },
                      { label: "영천" },
                      { label: "완도" },
                      { label: "원주" },
                      { label: "울릉도" },
                      { label: "울산" },
                      { label: "울진" },
                      { label: "인천" },
                      { label: "인제" },
                      { label: "임실" },
                      { label: "장수" },
                      { label: "장흥" },
                      { label: "전주" },
                      { label: "제주" },
                      { label: "제천" },
                      { label: "진주" },
                      { label: "춘천" },
                      { label: "충주" },
                      { label: "추풍령" },
                      { label: "태백" },
                      { label: "통영" },
                      { label: "포항" },
                      { label: "합천" },
                      { label: "홍천" },
                      { label: "화천" },
                    ]}
                  />
                  <InputBasic
                    label="위도"
                    name="Weather_Station_Latitude"
                    value="35.3456"
                    disabled="disabled"
                    readonly
                  />
                  <InputBasic
                    label="경도"
                    name="Weather_Station_Longitude"
                    value="34.5763"
                    disabled="disabled"
                    readonly
                  />
                </div>
                <div className={style.weatherMap}>
                  <p>(위도 : 33 ~39, 경도 125 ~131, 증가분: 0.05)</p>
                  <div className={style.map}>
                    <img src={map}></img>
                  </div>
                </div>
              </Section2>
            </div>
          </div>
          <div className={style.containerRight}>
            <div className={style.meteorologicalDataAnalysis}>
              <Section2 title="기상자료분석">
                <div className={style.weatherAnalyzeWrap}>
                  <table className={style.weatherTable}>
                    <thead>
                      <tr>
                        <th>구분</th>
                        <th>1월</th>
                        <th>2월</th>
                        <th>3월</th>
                        <th>4월</th>
                        <th>5월</th>
                        <th>6월</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>최고온도(℃)</th>
                        <td>3.8</td>
                        <td>8.3</td>
                        <td>11.9</td>
                        <td>19.1</td>
                        <td>24.7</td>
                        <td>29.2</td>
                      </tr>
                      <tr>
                        <th>최저온도(℃)</th>
                        <td>-10.4</td>
                        <td>-5.9</td>
                        <td>0.0</td>
                        <td>5.7</td>
                        <td>3.8</td>
                        <td>3.8</td>
                      </tr>
                      <tr>
                        <th>강수량(mm)</th>
                        <td>19.3</td>
                        <td>36.8</td>
                        <td>41.8</td>
                        <td>82.7</td>
                        <td>96.4</td>
                        <td>142.6</td>
                      </tr>
                    </tbody>
                    <thead>
                      <tr>
                        <th>구분</th>
                        <th>7월</th>
                        <th>8월</th>
                        <th>9월</th>
                        <th>10월</th>
                        <th>11월</th>
                        <th>12월</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>최고온도(℃)</th>
                        <td>29.3</td>
                        <td>30.9</td>
                        <td>27.2</td>
                        <td>22.4</td>
                        <td>14.4</td>
                        <td>5.5</td>
                      </tr>
                      <tr>
                        <th>최저온도(℃)</th>
                        <td>20.8</td>
                        <td>21.4</td>
                        <td>17.0</td>
                        <td>8.9</td>
                        <td>0.4</td>
                        <td>-7.3</td>
                      </tr>
                      <tr>
                        <th>강수량(mm)</th>
                        <td>549.4</td>
                        <td>336.7</td>
                        <td>180.4</td>
                        <td>43.3</td>
                        <td>42.1</td>
                        <td>18.6</td>
                      </tr>
                    </tbody>
                  </table>

                  <div className={style.tab}>
                    <WeatherTabs />
                  </div>
                </div>
              </Section2>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default WeatherInfo;
