import React from "react";
import style from "./material.module.css";
import img1 from "../../assets/img/task-info-img1.png";
import materialSlave from "../../assets/img/modal/material_slave-min.png";

import Main from "../../layouts/main/Main";
import Section1 from "../../layouts/main/section1";
import Section2 from "../../layouts/main/section2";

import {
  InputBasic,
  InputDrop,
  InputDropUp,
} from "../../components/Input/Input";
import InfoIcon from "../../components/InfoIcon/InfoIcon";
import Title from "../../components/Title/Title";
import Card from "../../components/Card/Card";
import Modal from "../../components/Modal/Modal";
import PavementThicknessSection from "../../components/PavementThicknessSection/PavementThicknessSection";

import useSectionStore from "../../store/useSectionStore";
import useTaskStore from "../../store/useTaskStore";

const Material = () => {
  const {
    slabCoefficientThermalExpansion,
    setSlabCoefficientThermalExpansion,
    slabPoissonRatio,
    setSlabPoissonRatio,
    slabDryingShrinkageCoefficient,
    setSlabDryingShrinkageCoefficient,
    slabDayCompressiveStrength,
    setSlabDayCompressiveStrength,
    slabDayModulusElasticity,
    setSlabDayModulusElasticity,
    aNf,
    setANf,
    bNf,
    setBNf,
    cNf,
    setCNf,
    subbaseMaximumDryUnitWeight,
    setSubbaseMaximumDryUnitWeight,

    slabUnitWeight,
    setSlabUnitWeight,

    SubbaseUniformityCoefficient,
    setSubbaseUniformityCoefficient,

    subbasePassingRate4,
    setSubbasePassingRate4,

    subbaseOptimumMoistureContent,
    setSubbaseOptimumMoistureContent,

    leanUnitWeight,
    setLeanUnitWeight,

    leanPoissonRatio,
    setLeanPoissonRatio,

    leanModulusElasticity,
    setLeanModulusElasticity,

    subgradeMaximumDryUnitWeight,
    setSubgradeMaximumDryUnitWeight,

    subgradeUniformityCoefficient,
    setSubgradeUniformityCoefficient,

    subgradePassingRate2,
    setSubgradePassingRate2,

    subgradePlasticityIndex,
    setSubgradePlasticityIndex,

    subgradeOptimumMoistureContent,
    setSubgradeOptimumMoistureContent,
  } = useTaskStore();

  const { dimensions, setDimension } = useSectionStore((state) => ({
    dimensions: state.dimensions,
    setDimension: state.setDimension,
  }));

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDimension(name, parseFloat(value));
  };

  const handleBasicInputChange = (setter) => (e) => {
    setter(e.target.value);
  };
  console.log("Dimension:", dimensions);

  return (
    <Main>
      <div className={style.materialWrap}>
        <Section1></Section1>
        <div className={style.materialContentsContainer}>
          <div className={style.leftContents}>
            <div className={style.slaveWrap}>
              <Card>
                <Title
                  title="슬래브"
                  icon={
                    <InfoIcon
                      iconId="slave"
                      content={<img src={materialSlave} alt="슬래브" />}
                    />
                  }
                  style={{
                    borderBottom: "4px solid #0071CE",
                    paddingBottom: "10px",
                  }}
                ></Title>
                <div className={style.inputWrap}>
                  <InputDrop
                    label="굵은골재"
                    name="Slab_Coarse_Aggregate"
                    options={[
                      { label: "화강암", value: "23.84" },
                      { label: "석회암", value: "23.15" },
                      { label: "사암", value: "23.35" },
                      { label: "혼합골재", value: "option4" },
                    ]}
                  />
                  <InputBasic
                    label="잔골재"
                    name="Slab_Fine_Aggregate"
                    value="강모래"
                    type="text"
                    disabled="disabled"
                  />
                  <InputBasic
                    label="시멘트"
                    name="Slab_Cement"
                    value="Type-1"
                    disabled="disabled"
                  />
                  <InputBasic
                    label="단위중량(kN/m3)"
                    name="Slab_Unit_Weight"
                    type="number"
                    value={slabUnitWeight}
                    onChange={handleBasicInputChange(setSlabUnitWeight)}
                  />
                </div>
                <div className={style.inputWrap}>
                  <InputBasic
                    label="열팽창계수(με/℃)"
                    name="Slab_Coefficient_Thermal_Expansion"
                    type="number"
                    value={slabCoefficientThermalExpansion}
                    onChange={handleBasicInputChange(
                      setSlabCoefficientThermalExpansion
                    )}
                  />
                  <InputBasic
                    label="포아송비"
                    name="Slab_Poisson's_Ratio"
                    type="number"
                    value={slabPoissonRatio}
                    onChange={handleBasicInputChange(setSlabPoissonRatio)}
                  />
                  <InputBasic
                    label="건조수축계수"
                    name="Slab_Drying_Shrinkage_Coefficient"
                    type="number"
                    value={slabDryingShrinkageCoefficient}
                    onChange={handleBasicInputChange(
                      setSlabDryingShrinkageCoefficient
                    )}
                  />
                  <InputBasic
                    label="28일 압축강도(kPa)"
                    name="Slab_28_day_Compressive_Strength"
                    type="number"
                    value={slabDayCompressiveStrength}
                    onChange={handleBasicInputChange(
                      setSlabDayCompressiveStrength
                    )}
                  />
                  <InputBasic
                    label="28일 탄성계수 (GPa)"
                    name="Slab_28_day_Modulus_Elasticity"
                    type="number"
                    value={slabDayModulusElasticity}
                    onChange={handleBasicInputChange(
                      setSlabDayModulusElasticity
                    )}
                  />
                </div>
                <div className={style.predictionWrap}>
                  <p>피로균열 예측</p>
                  <div
                    className={style.prediction}
                    name="Slab_Fatigue_Crack_Prediction"
                  >
                    <label>aNf</label>
                    <input
                      name="aNf"
                      value={aNf}
                      type="number"
                      onChange={handleBasicInputChange(setANf)}
                    ></input>
                    <label>bNf</label>
                    <input
                      name="bNf"
                      value={bNf}
                      type="number"
                      onChange={handleBasicInputChange(setBNf)}
                    ></input>
                    <label>cNf</label>
                    <input
                      name="cNf"
                      value={cNf}
                      type="number"
                      onChange={handleBasicInputChange(setCNf)}
                    ></input>
                  </div>
                </div>
                <Title
                  title="린콘크리트층"
                  icon={
                    <InfoIcon
                      iconId="leanConcrete"
                      content={<img src={img1} alt="린콘트리트층" />}
                    />
                  }
                  style={{
                    borderBottom: "4px solid #E60020",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                />
                <div className={style.inputWrap}>
                  <InputDrop
                    label="굵은골재"
                    name="Lean_Coarse_Aggregate"
                    options={[
                      { label: "화강암", value: "option1" },
                      { label: "석회암", value: "option2" },
                      { label: "사암", value: "option3" },
                      { label: "혼합골재", value: "option4" },
                    ]}
                  />
                  <InputBasic
                    label="잔골재"
                    name="Lean_Fine_Aggregate"
                    value="강모래"
                    disabled="disabled"
                  />
                  <InputBasic
                    label="시멘트"
                    name="Lean_Cement"
                    value="Type-1"
                    disabled="disabled"
                  />
                  <InputBasic
                    label="단위중량(kN/m3)"
                    name="Lean_Unit_Weight"
                    value={leanUnitWeight}
                    onChange={handleBasicInputChange(setLeanUnitWeight)}
                  />
                  <InputBasic
                    label="포아송비"
                    name="Lean_Poisson's_Ratio"
                    value={leanPoissonRatio}
                    onChange={handleBasicInputChange(setLeanPoissonRatio)}
                  />
                  <InputBasic
                    label="탄성계수"
                    name="Lean_Modulus_Elasticity"
                    value={leanModulusElasticity}
                    onChange={handleBasicInputChange(setLeanModulusElasticity)}
                  />
                </div>
                <Title
                  title="보조기층"
                  icon={
                    <InfoIcon
                      iconId="auxiliaryBase"
                      content={<img src={img1} alt="보조기층" />}
                    />
                  }
                  style={{
                    borderBottom: "4px solid rgb(228 216 70)",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                />
                <div className={style.inputWrap}>
                  <InputDrop
                    label="보조기층 입도"
                    options={[
                      { label: "SB-1", value: "option1" },
                      { label: "SB-2", value: "option1" },
                    ]}
                  />
                  <InputBasic
                    label="최대건조단위중량(kN/㎥)"
                    name="Subbase_Maximum_Dry_Unit_Weight"
                    customStyle={{ width: "170px" }}
                    type="number"
                    value={subbaseMaximumDryUnitWeight}
                    onChange={handleBasicInputChange(
                      setSubbaseMaximumDryUnitWeight
                    )}
                  />
                  <InputBasic
                    label="균등계수"
                    name="Subbase_Uniformity_Coefficient"
                    type="number"
                    value={SubbaseUniformityCoefficient}
                    onChange={handleBasicInputChange(
                      setSubbaseUniformityCoefficient
                    )}
                  />
                  <InputBasic
                    label="#4체 통과율(%)"
                    name="Subbase_Passing_Rate_of_#4_Slave"
                    type="number"
                    value={subbasePassingRate4}
                    onChange={handleBasicInputChange(setSubbasePassingRate4)}
                  />
                  <InputBasic
                    label="최적함수비(%)"
                    name="Subbase_Optimum_Moisture_Content"
                    type="number"
                    value={subbaseOptimumMoistureContent}
                    onChange={handleBasicInputChange(
                      setSubbaseOptimumMoistureContent
                    )}
                  />
                </div>
                <Title
                  title="노상"
                  icon={
                    <InfoIcon
                      iconId="onTheStreet"
                      content={<img src={img1} alt="노상" />}
                    />
                  }
                  style={{
                    borderBottom: "4px solid #00AD26",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                />
                <div className={style.inputWrap}>
                  <InputDrop
                    label="노상토 구분"
                    name="Subgrade_Roadbed_Soil_Classification"
                    options={[
                      { label: "조립질 노상토", value: "option1" },
                      { label: "세립질 노상토", value: "option2" },
                    ]}
                  />
                  <InputBasic
                    label="최대건조단위중량(kN/㎥)"
                    name="Subgrade_Maximum_Dry_Unit_Weight"
                    type="number"
                    value={subgradeMaximumDryUnitWeight}
                    onChange={handleBasicInputChange(
                      setSubgradeMaximumDryUnitWeight
                    )}
                  />
                  <InputBasic
                    label="균등계수"
                    name="Subgrade_Uniformity_Coefficient"
                    type="number"
                    value={subgradeUniformityCoefficient}
                    onChange={handleBasicInputChange(
                      setSubgradeUniformityCoefficient
                    )}
                  />
                </div>
                <div className={style.inputWrap}>
                  <InputBasic
                    label="#200채 통과율(%)"
                    name="Subgrade_Passing_Rate_of_#200_Slave"
                    type="number"
                    value={subgradePassingRate2}
                    onChange={handleBasicInputChange(setSubgradePassingRate2)}
                  />
                  <InputBasic
                    label="소성지수"
                    name="Subgrade_Plasticity_Inde"
                    type="number"
                    disabled="disabled"
                    value={subgradePlasticityIndex}
                    onChange={handleBasicInputChange(
                      setSubgradePlasticityIndex
                    )}
                  />
                  <InputBasic
                    label="최적함수비(%)"
                    name="Subgrade_Optimum_Moisture_Content"
                    type="number"
                    value={subgradeOptimumMoistureContent}
                    onChange={handleBasicInputChange(
                      setSubgradeOptimumMoistureContent
                    )}
                  />
                  <InputDropUp
                    label="암반층까지 깊이(m)"
                    name="Subgrade_Depth_to_Bedrock"
                    type="number"
                    options={[
                      { label: "1", value: "option1" },
                      { label: "2", value: "option2" },
                      { label: "3", value: "option3" },
                      { label: "4", value: "option4" },
                      { label: "5", value: "option5" },
                      { label: "6", value: "option6" },
                      { label: "7", value: "option7" },
                      { label: "8", value: "option8" },
                      { label: "9", value: "option9" },
                      { label: "10", value: "option10" },
                    ]}
                  />
                </div>
              </Card>
            </div>
          </div>
          <div className={style.rightContents}>
            <div className={style.concreteLayer}>
              <Section2 title="콘크리트 포장 단면 개략도">
                <div className={style.loadImg}>
                  <PavementThicknessSection
                    dimensions={dimensions}
                    handleInputChange={handleInputChange}
                  />
                </div>
              </Section2>
            </div>
          </div>
        </div>
      </div>
      <Modal />
    </Main>
  );
};

export default Material;
