import React from "react";
import style from "./Table.module.css";

const MonthlyTrafficTable = () => {
  const months = [
    "1월",
    "2월",
    "3월",
    "4월",
    "5월",
    "6월",
    "7월",
    "8월",
    "9월",
    "10월",
    "11월",
    "12월",
  ];

  const trafficData = {
    "2종": [
      8707, 8707, 9269, 9550, 9643, 9456, 8707, 9456, 9924, 9643, 10018, 9362,
    ],
    "3종": [
      8000, 8100, 8500, 8700, 8900, 8600, 8200, 8800, 9000, 8800, 9200, 9100,
    ],
    "4종": [
      7500, 7600, 7900, 8000, 8200, 7800, 7600, 8300, 8500, 8400, 8700, 8600,
    ],
    "5종": [
      7000, 7100, 7300, 7500, 7600, 7400, 7200, 7800, 8000, 7900, 8200, 8100,
    ],
    "6종": [
      6800, 6900, 7100, 7300, 7400, 7200, 7000, 7600, 7800, 7700, 8000, 7900,
    ],
    "7종": [
      6600, 6700, 6900, 7100, 7200, 7000, 6800, 7400, 7600, 7500, 7800, 7700,
    ],
    "8종": [
      6400, 6500, 6700, 6900, 7000, 6800, 6600, 7200, 7400, 7300, 7600, 7500,
    ],
    "9종": [
      6200, 6300, 6500, 6700, 6800, 6600, 6400, 7000, 7200, 7100, 7400, 7300,
    ],
    "10종": [
      6000, 6100, 6300, 6500, 6600, 6400, 6200, 6800, 7000, 6900, 7200, 7100,
    ],
    "11종": [
      5800, 5900, 6100, 6300, 6400, 6200, 6000, 6600, 6800, 6700, 7000, 6900,
    ],
    "12종": [
      5600, 5700, 5900, 6100, 6200, 6000, 5800, 6400, 6600, 6500, 6800, 6700,
    ],
  };

  return (
    <table className={style.table} style={{ fontSize: "14px" }}>
      <thead>
        <tr>
          <th colSpan={13}>월별 설계차로 교통량</th>
        </tr>
        <tr>
          <th>구분</th>
          <th>1종</th>
          {[...Array(11)].map((_, index) => (
            <th key={index + 2}>{index + 2}종</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {months.map((month, monthIndex) => (
          <tr key={monthIndex}>
            <th>{month}</th>
            <td>
              <input
                type="number"
                style={{ width: "45px" }}
                // placeholder={`${month}`}
              />
            </td>
            {[...Array(11)].map((_, index) => (
              <td key={index + 2}>
                {trafficData[`${index + 2}종`][monthIndex]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default MonthlyTrafficTable;
