import React from "react";
import ButtonStyle from "./Button.module.css";

/*
css에 적용상태에 따라 버튼의 크기 및 컬러 변경가능.
*/

const Button = ({ children, onClick, className = "", disabled }) => {
  return (
    <button
      className={`${ButtonStyle.button} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
