import React, { useEffect, useState } from "react";
import style from "./index.module.css";

import { useLocation, useNavigate } from "react-router-dom";

import Button from "../../../components/Button/Button";
import ButtonStyle from "../../../components/Button/Button.module.css";
import { menuText } from "../../../data/nav";

/*
저장 버튼 클릭 다음 path로 넘어가는 섹션
*/

const Section1 = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [buttonText, setButtonText] = useState("저장");
  const location = useLocation();
  const navigate = useNavigate();

  const currentPath = location.pathname;
  let title = "";
  let nextPath = null;

  const allItems = menuText.flatMap((menu) => menu.items);

  allItems.forEach((item, index) => {
    if (item.path === currentPath) {
      title = item.title;

      for (let i = index + 1; i < allItems.length; i++) {
        if (allItems[i].title !== "종방향 철근") {
          nextPath = allItems[i].path;
          break;
        }
      }
    }
  });

  useEffect(() => {
    if (currentPath.includes("commonality-prediction")) {
      setButtonText("Excel");
    } else if (currentPath.includes("result-report")) {
      setButtonText("출력");
    } else {
      setButtonText("저장");
    }
  }, [currentPath]);

  const handleNextClick = () => {
    if (nextPath) {
      navigate(nextPath);
    } else {
      setIsDisabled(true);
      alert("다음 페이지가 없습니다.");
    }
  };

  return (
    <div className={style.sectionWrap}>
      <div className={style.titleWrap}>
        <h2>{title}</h2>
        <div className={style.buttonWrap}>
          <Button
            className={ButtonStyle.next}
            disabled={isDisabled}
            onClick={handleNextClick}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Section1;
