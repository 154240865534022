import {
  BsInfoCircle,
  BsCheckCircle,
  BsDashCircle,
  BsExclamationTriangle,
} from "react-icons/bs";
import { PiCaretDown, PiCaretUp, PiCalendarCheckLight } from "react-icons/pi";
import { BiRevision } from "react-icons/bi";

export const icons = {
  BsInfoCircle,
  PiCaretDown,
  PiCaretUp,
  PiCalendarCheckLight,
  BsCheckCircle,
  BsDashCircle,
  BsExclamationTriangle,
  BiRevision,
};
